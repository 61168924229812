// File: Components/Common/FilterComponent.tsx
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';

// Custom input component that integrates the calendar icon into the input.
const CustomDateInput = React.forwardRef(
    (
        { value, onClick }: { value?: string; onClick?: () => void },
        ref: React.Ref<HTMLInputElement>
    ) => (
        <div className="input-group" onClick={onClick} style={{ cursor: 'pointer' }}>
            <input
                type="text"
                className="form-control"
                ref={ref}
                value={value}
                onClick={onClick}
                readOnly
            />
            <div className="input-group-append">
        <span className="input-group-text" style={{ display: 'flex', alignItems: 'center' }}>
          <i className="mdi mdi-calendar" />
        </span>
            </div>
        </div>
    )
);
CustomDateInput.displayName = "CustomDateInput";

interface FilterComponentProps {
    isAdmin: boolean;
    managers: { id: number; name: string }[];
    clients: { id: number; name: string }[];
    clientSections?: { id: string; name: string; clients: { id: number; name: string }[] }[];
    onFilterChange?: (filters: {
        startDate: Date | null;
        endDate: Date | null;
        selectedManager: string;
        selectedClient: string;
    }) => void;
    defaultSelectedManager?: string;
    defaultStartDate?: Date;
    defaultEndDate?: Date;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
                                                             isAdmin,
                                                             managers,
                                                             clients,
                                                             clientSections,
                                                             onFilterChange,
                                                             defaultSelectedManager,
                                                             defaultStartDate,
                                                             defaultEndDate,
                                                         }) => {
    // Compute the current week's Monday and Sunday if no default dates are provided.
    const getCurrentWeek = () => {
        const now = new Date();
        const monday = new Date(now);
        const day = monday.getDay();
        // If today is Sunday (day === 0), subtract 6 days; otherwise subtract (day - 1).
        monday.setDate(monday.getDate() - (day === 0 ? 6 : day - 1));
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return { monday, sunday };
    };

    const { monday, sunday } = getCurrentWeek();
    const initialStartDate = defaultStartDate || monday;
    const initialEndDate = defaultEndDate || sunday;
    const initialManager = defaultSelectedManager || "";

    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
    const [selectedManager, setSelectedManager] = useState<string>(initialManager);
    const [selectedClient, setSelectedClient] = useState<string>("All");

    // Update state if default values change.
    useEffect(() => {
        setStartDate(defaultStartDate || monday);
        setEndDate(defaultEndDate || sunday);
    }, [defaultStartDate, defaultEndDate]);

    useEffect(() => {
        setSelectedManager(defaultSelectedManager || "");
    }, [defaultSelectedManager]);

    // Notify parent component when any filter changes.
    useEffect(() => {
        if (onFilterChange) {
            onFilterChange({ startDate, endDate, selectedManager, selectedClient });
        }
    }, [startDate, endDate, selectedManager, selectedClient, onFilterChange]);

    const handleManagerChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedManager(e.target.value);
        setSelectedClient("All");
    };

    return (
        <Form className="mb-4">
            <Row form>
                <Col md={3}>
                    <FormGroup>
                        <Label for="start_date">Start Date</Label>
                        <DatePicker
                            id="start_date"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Select start date"
                            customInput={<CustomDateInput />}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="end_date">End Date</Label>
                        <DatePicker
                            id="end_date"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText="Select end date"
                            customInput={<CustomDateInput />}
                        />
                    </FormGroup>
                </Col>
                {isAdmin && (
                    <Col md={3}>
                        <FormGroup>
                            <Label for="manager">Manager</Label>
                            <Input
                                type="select"
                                id="manager"
                                value={selectedManager}
                                onChange={handleManagerChange}
                            >
                                <option value="">Select Manager</option>
                                {managers.map((manager) => (
                                    <option key={manager.id} value={manager.id.toString()}>
                                        {manager.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                )}
                <Col md={isAdmin ? 3 : 6}>
                    <FormGroup>
                        <Label for="client">Client</Label>
                        <Input
                            type="select"
                            id="client"
                            value={selectedClient}
                            onChange={(e) => setSelectedClient(e.target.value)}
                        >
                            <option value="All">All</option>
                            {clients && !clientSections && clients.map((client) => (
                                <option key={client.id} value={client.id.toString()}>
                                    {client.name}
                                </option>
                            ))}
                            {clientSections && clientSections.map((section) => (
                                <optgroup key={section.id} label={section.name}>
                                    {section.clients.map((client) => (
                                        <option key={client.id} value={client.id.toString()}>
                                            {client.name}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
};

export default FilterComponent;
