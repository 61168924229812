import React, { useEffect, useState } from "react";

interface CachedImageProps {
    src: string;
    alt?: string;
    id: string | number;
}

const CachedImage: React.FC<CachedImageProps> = ({ src, alt, id }) => {
    const [cachedSrc, setCachedSrc] = useState<string | null>(null);

    useEffect(() => {
        // Create a new image to pre-load.
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setCachedSrc(src);
        };
        // Optionally, you can add error handling here.
    }, [src, id]);

    return cachedSrc ? (
        <img src={cachedSrc} alt={alt} style={{ maxWidth: "100px", maxHeight: "100px" }} />
    ) : (
        <div style={{ width: "100px", height: "100px", backgroundColor: "#eee" }}>Loading…</div>
    );
};

export default CachedImage;
