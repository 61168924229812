import React, { useState } from "react";
import { Table, Input } from "reactstrap";

export interface Section {
    sectionName: string;
    data: any[]; // The table data for this section (e.g. events for a client)
}

export interface SectionTableContainerProps {
    sections: Section[]; // New prop: array of sections
    columns: any;        // The columns definition
    divClassName?: string;
    tableClass?: string;
    theadClass?: string;
    isBordered?: boolean;
    isGlobalFilter?: boolean;
    isPagination?: boolean;
    paginationWrapper?: string;
    SearchPlaceholder?: string;
    pagination?: string;
    handleUserClick?: any;
    handleRowClick?: (row: any) => void;
    buttonClass?: string;
    buttonName?: string;
    isAddButton?: boolean;
    customRightTools?: JSX.Element;
    customLeftTools?: JSX.Element;
    isCustomPageSize?: boolean;
    isJobListGlobalFilter?: boolean;
    isNoData?: boolean;
    noDataText?: string;
    showExportButtons?: boolean;
    pageSize?: number;
}

const SectionTableContainer: React.FC<SectionTableContainerProps> = ({
                                                                         sections,
                                                                         columns,
                                                                         divClassName,
                                                                         tableClass,
                                                                         theadClass,
                                                                         isBordered,
                                                                         isGlobalFilter = true,
                                                                         SearchPlaceholder = "Search...",
                                                                         handleRowClick,
                                                                         ...rest
                                                                     }) => {
    const [searchTerm, setSearchTerm] = useState("");

    // Filtering logic: Searches across all fields in all sections
    const filteredSections = sections.map((section) => ({
        ...section,
        data: section.data.filter((row) =>
            columns.some((col: any) =>
                row[col.accessorKey]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        ),
    }));

    return (
        <div
            className={divClassName ? divClassName : "table-responsive"}
            style={{
                overflowY: "auto",
            }}
        >
            {/* Global Search Filter */}
            {isGlobalFilter && (
                <div className="mb-3">
                    <Input
                        type="text"
                        placeholder={SearchPlaceholder}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            )}

            {filteredSections.map((section, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                    {/* Sticky header for the section */}
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f8f9fa", // Set background color here
                            padding: "10px",
                            borderBottom: "1px solid #ddd",
                        }}
                    >
                        {section.sectionName}
                    </div>
                    {/* Render a table for this section's data */}
                    <Table hover className={tableClass} bordered={isBordered}>
                        <thead className={theadClass}>
                        <tr>
                            {columns.map((col: any, idx: number) => (
                                <th key={idx}>{col.header}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {section.data.length === 0 ? (
                            <tr style={{ textAlign: "center" }}>
                                <td colSpan={columns.length}>No data available</td>
                            </tr>
                        ) : (
                            section.data.map((row: any, rowIdx: number) => (
                                <tr key={rowIdx} onClick={() => handleRowClick && handleRowClick(row)}>
                                    {columns.map((col: any, colIdx: number) => (
                                        <td key={colIdx}>
                                            {typeof col.cell === "function"
                                                ? col.cell({
                                                    row: { original: row },
                                                    getValue: () => row[col.accessorKey],
                                                })
                                                : row[col.accessorKey]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        )}
                        </tbody>
                    </Table>
                </div>
            ))}
        </div>
    );
};

export default SectionTableContainer;
