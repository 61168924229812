import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addStartedVideo, deleteStartedVideo, getStartedVideos, modifyEnabledVideoIds, updateStartedVideo } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import GettingStartedVideoModal from "Components/Modal/GettingStartedVideoModal";
import Thumbnail from "Components/Common/Thumbnail";
import { convertUTC2Local } from "utils";
import { UserRoleKeys, userManager } from "utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const GettingStartedPage = ({title}) => {
    const navigate = useNavigate();
    const userRole = userManager.getUserRole();
    const [startedVideosData, setStartedVideosData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [videoIds, setVideoIds] = useState<number[]>([]);

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateStartedVideo = (newStartedVideo: any) => {
        const createStartedVideo = async () => {
            try {
                const response = await addStartedVideo(newStartedVideo); // Add parameters if needed
                if (response.success) {

                    setStartedVideosData((state) => [...state, response.data]);
                    toast.success("Created new started video.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create started video.");
                }
            } catch (error) {

                toast.error("Failed to create started video.");
            }
        };

        createStartedVideo();
    }

    const handleEditStartedVideo = (startedVideo: any) => {
        const modifyStartedVideo = async () => {
            try {
                const response = await updateStartedVideo(startedVideo);
                if (response.success) {
                    setStartedVideosData((state) => {
                        const updatedVideos = state.map((item) =>
                            item.id === startedVideo.id ? startedVideo : item
                        );

                        // Sort the updated list by `order`
                        const sortedVideos = updatedVideos.sort((a, b) => a.order - b.order);

                        return [...sortedVideos];
                    });
                    toggleEditModal();
                } else {
                    toast.error(response.error || "Failed to update started video.");
                }
            } catch (error) {
                toast.error("Failed to update started video.");
            }
        };

        modifyStartedVideo();
    };


    const handleDeleteStartedVideo = () => {
        const removeStartedVideo = async () => {
            try {
                const response = await deleteStartedVideo(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setStartedVideosData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove started video.");
                }
            } catch (error) {

                toast.error("Failed to remove started video.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeStartedVideo()
        }
    }

    const handleToggleVideo = (id: number) => {
        const foundIndex = videoIds.findIndex((item) => item === id);

        if (foundIndex >= 0) {
            const newVideoIds = [...videoIds];
            newVideoIds.splice(foundIndex, 1);
            modifyEnabledVideoIds(newVideoIds)
                .then(() => {
                    // exist
                    setVideoIds([...newVideoIds]);
                    const userData = userManager.getUser();
                    userManager.setUserData({
                        ...userData || {},
                        tenantDetails: {
                            ...(userData?.tenantDetails || {}),
                            settings: {
                                ...(userData?.tenantDetails.settings || {}),
                                enabledGettingStartedVideoIds: [...newVideoIds]
                            }
                        } as any
                    } as any)
                })
        } else {
            const newVideoIds = [...videoIds, id];

            modifyEnabledVideoIds(newVideoIds)
            .then(() => {
                // not exist
                setVideoIds([...newVideoIds]);
                const userData = userManager.getUser();
                userManager.setUserData({
                    ...userData || {},
                    tenantDetails: {
                        ...(userData?.tenantDetails || {}),
                        settings: {
                            ...(userData?.tenantDetails.settings || {}),
                            enabledGettingStartedVideoIds: [...newVideoIds]
                        }
                    } as any
                } as any)
            })
        }
    }

    useEffect(() => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            const videoIds = userManager.getUser()?.tenantDetails.settings.enabledGettingStartedVideoIds ?? [];

            setVideoIds(videoIds);
        }
    }, [userRole, userManager]);

    useEffect(() => {
        const fetchStartedVideo = async () => {
            try {
                const response = await getStartedVideos(1); // Add parameters if needed
                if (response.success) {

                    const sortedVideos = response.data.gettingStartedVideos.sort((a, b) => a.order - b.order);
                    setStartedVideosData(sortedVideos);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch started videos.");
                }
            } catch (error) {

                toast.error("Failed to fetch started videos.");
            }
        };
        // fetchManagers();
        fetchStartedVideo();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Order',
                accessorKey: 'order',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Video',
                accessorKey: 'thumbnailUrl',
                cell: (info) => <Thumbnail url={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Subtitle',
                accessorKey: 'subtitle',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (info) => <div style={{ whiteSpace: 'normal' }}>{info.getValue()}</div>
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    if (userRole === UserRoleKeys.RoleCorp) {
                        return (
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-success" onClick={() => {
                                    setValueToBeModified(cellProps.row.original)
                                    toggleEditModal()
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                    <UncontrolledTooltip placement="top" target="editTooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                                <button className="btn text-danger p-0" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleDeleteModal();
                                }}>
                                    <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </button>

                            </div>
                        )
                    } else if (userRole === UserRoleKeys.RoleAdmin) {
                        const id = cellProps.row.original.id;

                        return (
                            <div className="m-auto">
                                <input
                                    checked={videoIds?.includes(id)}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="video_show_on"
                                    onChange={() => {
                                        handleToggleVideo(id)
                                    }}
                                />
                                <label
                                className="form-check-label"
                                htmlFor="video_show_on"
                                style={{ marginLeft: '8px' }}
                                >
                                    Show
                                </label>
                            </div>
                        )
                    }
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal, userRole, videoIds, handleToggleVideo]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={startedVideosData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={userRole === UserRoleKeys.RoleCorp}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search started videos..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Started Video"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <GettingStartedVideoModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateStartedVideo} />
                            <GettingStartedVideoModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditStartedVideo} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this started video? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteStartedVideo}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GettingStartedPage;
