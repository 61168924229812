import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import './print.css';

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";

//import Images
import verification from "../../assets/images/verification-img.png";

//import
import {
  getEvents as onGetEvents,
  getCategories as onGetCategories,
  getGoalSteps as onGetGoalSteps,
  getGoalStepActivities as onGoalStepActivities,
  addNewEvent as onAddNewEvent,
  deleteAllEvents as onDeleteAllEvents,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
  getMultipleEvents as onGetMultipleEvents

} from "../../slices/calendar/thunk"

import {clearEventsSuccess, resetAddEventSuccess} from "../../slices/calendar/reducer";

import DeleteModal from "./DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import TableContainer from "../../Components/Common/TableContainer";
import {addNewCustomActivity, getClients} from "helpers/backend_helper";
import {toast, ToastContainer} from "react-toastify";
import RoleCell from "../../Components/Common/RoleCell";
import {userManager, UserRoleKeys} from "../../utils/UserManager";
import {Link} from "react-router-dom";
import {OriginalEvent} from "./GoalInterface";
import ViewGoalModal from "./ViewGoalModal";
interface column {
  header: string,
  accessorKey: string,
  enableColumnFilter: boolean,
  enableSorting: boolean,
}
type UpdateEvent = {
  goalStepActivityId?: number;
  newScheduledDatetime: string;
  isFullDayEvent?: number;
  frequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | undefined; // Adjust based on your API's accepted values
  repetitionEndDate?: string | undefined;
  count?: number | null;
  interval?: number | undefined;
  weekdays?: string| null;
  dayOfMonth?: number | null;
};
type NewEvent = {
  goalStepActivityId: number;
  scheduledDatetime: string;
  isFullDayEvent: number;
  frequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | undefined; // Adjust based on your API's accepted values
  repetitionEndDate?: string | undefined;
  count?: number | null;
  interval?: number | undefined;
  weekdays?: string| null;
  dayOfMonth?: number | null;
};
interface Goal {
  id: number;
  title: string;
  createdAt: string;
  // Add any other properties as needed
}


const Calender = (props: any) => {
  const currentUser = userManager;
  //meta title
  document.title = "Calendar | Eckerd Connects - Admin";
  // Define calendarRef with the specific type for a div element
  const calendarRef = useRef<HTMLDivElement>(null);
  const calenderDefaultCategories = [
    {
      id: 1,
      title: "New Event Planning",
      type: "bg-success",
    },
    {
      id: 2,
      title: "Meeting",
      type: "bg-info",
    },
    {
      id: 3,
      title: "Generating Reports",
      type: "bg-warning",
    },
    {
      id: 4,
      title: "Create New theme",
      type: "bg-danger",
    },
  ];
  const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
  const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';
  const clientTitleSingular = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Client';

  const managerTitle = (!currentUser.isCorporate() && tenantSetting?.['use_coach_name']) ? tenantSetting?.['use_coach_name'] : 'Managers';
  const managerTitleSingular = (!currentUser.isCorporate() && tenantSetting?.['use_coach_name']) ? tenantSetting?.['use_coach_name'] : 'Manager';
// Function to get a random type from calenderDefaultCategories
  const getRandomType = () => {
    const randomIndex = Math.floor(Math.random() * calenderDefaultCategories.length);
    return calenderDefaultCategories[randomIndex].type;
  };
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.calendar,
    (calendar) => ({
      events: calendar.events,
      categories: calendar.categories || [],
      steps: calendar.steps ? calendar.steps : [],
      activities: calendar.activities ? calendar.activities : []
    })

  );
  const [repeatEnd, setRepeatEnd] = useState('');
  const [repeatEndDate, setRepeatEndDate] = useState('');
  const [repeatEndTimes, setRepeatEndTimes] = useState('1');
  const addEventSuccess = useSelector((state: any) => state.calendar.addEventSuccess);
  const { events, categories, steps, activities }: any = useSelector(selectProperties);
  const [isCustomActivitySelected, setIsCustomActivitySelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteRepeatingModal, setDeleteRepeatingModal] = useState<boolean>(false);
  const [modalCategory, setModalCategory] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedDay, setSelectedDay] = useState<any>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [selectedGoalStepId, setSelectedGoalStepId] = useState(null);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState(null);
  const [clientsData, setClientsData] = useState<any[]>([]);

  const [isRepeating, setIsRepeating] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(0);
  const [isSameMonth, setIsSameMonth] = useState<boolean>(true);
  const [repeatFrequency, setRepeatFrequency] = useState('Day(s)'); // Default to 'Day(s)'
  const [repeatInterval, setRepeatInterval] = useState('1'); // Default interval
  const [currentStatusCompleted, setCurrentStatusCompleted] = useState(false);
  // Define the days array
  const days = ['M', 'T', 'W', 'R', 'F', 'S', 'D'];
  const [originalEvent, setOriginalEvent] = useState<OriginalEvent | null>(null);
  const currentDayIndex = new Date().getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
// Adjust index to match your days array (e.g., 'M' for Monday is at index 0 in your array, but Monday is 1 in getDay())
  const adjustedIndex = currentDayIndex === 0 ? 6 : currentDayIndex - 1;

  const initialSelectedDays = new Array(days.length).fill(false);
  initialSelectedDays[adjustedIndex] = true; // Mark the current day as selected

  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const handleDayChange = (index) => {
    // Don't allow changes for the current day
    if (index === adjustedIndex) return;

    const updatedSelectedDays = [...selectedDays];
    updatedSelectedDays[index] = !updatedSelectedDays[index];
    setSelectedDays(updatedSelectedDays);
  };
  const memoizedEvents = useMemo(() => events, [events]);
  useEffect(() => {
    console.log(events);
  }, [events]);

  useEffect(() => {
    console.log(currentMonth, currentYear);
    if (selectedClientId)
    {
      dispatch(clearEventsSuccess());
      if (isSameMonth)
      {
        dispatch(onGetEvents({ clientId: selectedClientId, client: selectedClient, month: currentMonth, year: currentYear, complete: currentStatusCompleted ? 1 : 0 }));
      }
      else
      {
        dispatch(onGetMultipleEvents({ clientId: selectedClientId, client: selectedClient, month: currentMonth, year: currentYear, complete: currentStatusCompleted ? 1 : 0 }));
      }

    }

  }, [currentMonth, currentYear]);
  const generateIntervalOptions = () => {
    let options: JSX.Element[] = []; // Explicitly type the array
    let maxInterval = 0;

    switch (repeatFrequency) {
      case 'Day(s)':
        maxInterval = 31;
        break;
      case 'Week(s)':
        maxInterval = 4;
        break;
      case 'Month(s)':
        maxInterval = 12;
        break;
      default:
        maxInterval = 0;
    }

    for (let i = 1; i <= maxInterval; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }

    return options;
  };

  useEffect(() => {
    console.log("FETCH");

    const fetchAllClients = async () => {
      let allClients: any[] = [];
      let offset = 0;
      const limit = 50; // Increased to 50 for better efficiency
      let hasMoreData = true;



      try {
        // Ensure currentUser and dbUser are defined and have a groupIds array
        const user = currentUser.getUser();
        const firstGroupId = user?.dbUser?.groupIds?.length ? user.dbUser.groupIds[0] : null;

        while (hasMoreData) {
          const response = await getClients(1, offset, limit, currentUser.isManager() ? firstGroupId : null);

          if (response.success) {
            const newClients = response.data.clients || [];

            allClients = [...allClients, ...newClients];
            offset += limit;

            if (newClients.length < limit) {
              hasMoreData = false; // ✅ Stop if last batch has fewer than `limit` items
            }
          } else {
            toast.error(response.error || "Failed to fetch clients.");
            hasMoreData = false;
          }
        }

        // ✅ Update state only after all data is collected
        setClientsData(allClients.length ? allClients : []);

        // ✅ Automatically set initial client if available
        if (allClients.length > 0) {
          const client = allClients[0];
          const date = new Date();
          setCurrentMonth(date.getMonth() + 1);
          setCurrentYear(date.getFullYear());
          setSelectedClient(client);
          setSelectedClientName(client.name);
          setSelectedClientId(client.id);
        }
      } catch (error) {
        toast.error("Failed to fetch clients.");
        console.error(error);
      } finally {

      }
    };


    fetchAllClients();
  }, []);

  useEffect(() => {

   if (selectedClientId)
    {
      dispatch(clearEventsSuccess());

      dispatch(onGetEvents({ clientId: selectedClientId, client: selectedClient, month: currentMonth, year: currentYear, complete: currentStatusCompleted ? 1 : 0 }));
    }

  }, [selectedClientId, currentStatusCompleted, dispatch]);

  useEffect(() => {

    if (addEventSuccess && selectedClientId) {
      dispatch(clearEventsSuccess());
      dispatch(onGetEvents({ clientId: selectedClientId, client: selectedClient, month: currentMonth, year: currentYear, complete: currentStatusCompleted ? 1 : 0 }));
      dispatch(resetAddEventSuccess());
    }


  }, [addEventSuccess, dispatch]);
  const toggle = () => {

    if (modalCategory) {
      setModalCategory(false);
      setEvent(null);
      setIsEdit(false);
      categoryValidation.resetForm()
    } else {
      setModalCategory(true);
    }
  };
  const handleUserClick = useCallback((arg: any) => {
    const user = arg;

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
        year,
        month,
        day,
        currentHour,
        currentMin,
        currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);

    // Format the date and time for the form inputs
    const formattedDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMin.toString().padStart(2, '0')}`;

    // Update Formik's values - Assuming you have access to setFieldValue from Formik's context or props
    categoryValidation.setFieldValue("selectDate", formattedDate);
    categoryValidation.setFieldValue("selectTime", formattedTime);
    categoryValidation.setFieldValue("addDay", false);
    setSelectedActivityId(null);
    setSelectedGoalStepId(null);
    setSelectedGoalId(null);

    toggle();





  }, [toggle]);

  const columns = useMemo(
      () => [

        {
          header: 'Name',
          accessorKey: 'name',
          enableColumnFilter: false,
          enableSorting: true,
          // Modify the cell rendering to visually indicate the selected user
          cell: (cellProps: any) => {
            const isClientSelected = cellProps.row.original.id === selectedClientId;
            return (
                <div className={`name-cell ${isClientSelected ? 'text-success' : ''}`}>
                  <Link to="#!"
                        onClick={(event: any) => {
                          event.preventDefault();
                          const userData = cellProps.row.original;
                          setSelectedClient(userData);
                          setSelectedClientId(userData.id);
                          setSelectedClientName(userData.name);

                        }}>

                    {cellProps.row.original.name}
                  </Link>


                  {isClientSelected && (
                      <i className="mdi mdi-arrow-right text-success ml-2" /> // Example icon to show selection
                  )}
                </div>
            );
          },
        },
        {
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cellProps: any) => {
            return (
                <div className="d-flex gap-3">
                  <Link to="#!" className="text-info"
                        onClick={(event: any) => {
                          event.preventDefault();
                          const userData = cellProps.row.original;
                          handleUserClick(userData);
                        }}>
                    <i className="mdi mdi-plus-circle-outline me-1"/>
                    Add Activity
                  </Link>
                </div>
            );
          },
        }
        // Add more columns as needed
      ],
      [handleUserClick]
  );


  useEffect(() => {
    if (selectedGoalId)
    {
      dispatch(onGetGoalSteps(selectedGoalId));
    }

  }, [selectedGoalId]);

  useEffect(() => {
    if (selectedGoalStepId) {
      // Correct property names as per GoalStepActivitiesParams type
      dispatch(onGoalStepActivities({ clientId: selectedClientId, goalId: selectedGoalId, goalStepId: selectedGoalStepId }));

    }
  }, [selectedGoalStepId]);

  useEffect(() => {
    dispatch(onGetCategories());

    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, [dispatch]);

  const [event, setEvent] = useState<any>({});
  const validationSchema = Yup.object().shape({
    goal: Yup.string().required('Goal is required.'),
    goalStep: Yup.string().required('Goal Step is required.'),
    goalStepActivity: Yup.string().required('Goal Step Activity is required.'),
    isCustomActivitySelected: Yup.boolean(),  // Assuming this is a boolean in your form
        customActivity: Yup.string()
            .when('isCustomActivitySelected', (isCustomActivitySelected: any, schema: Yup.StringSchema) => {

               console.log('test',isCustomActivitySelected);
              return isCustomActivitySelected[0] === true
                  ? schema.required('Custom activity name is required.')
                  : schema.notRequired();
                }
            )
    ,
    customActivityDescription: Yup.string().notRequired(),
    allDay: Yup.boolean(),
    selectDate: Yup.date().required('Date is required.'),
    selectTime: Yup.string().when('allDay', (allDay: any, schema) =>
        allDay === false ? schema.required('Time is required when not an all-day event.') : schema.notRequired()
    ),
    repeat: Yup.string().required('You must choose one of them.'),
    repeatInterval: Yup.number().when('repeat', (repeat: any, schema) =>
        repeat === 'repeat' ? schema.required('Repeat interval is required.') : schema.notRequired()
    ),
    repeatFrequency: Yup.string().when('repeat', (repeat: any, schema) =>
        repeat === 'repeat' ? schema.required('Repeat frequency is required.') : schema.notRequired()
    ),
    repeatEnd: Yup.string().when('repeat', ([repeat]: any, schema) =>
        repeat === 'repeat' ? schema.required('Please specify how this event repeats.') : schema.notRequired()
    ),
    repeatEndDate: Yup.date().when('repeatEnd', ([repeatEnd]: any, schema) =>
        repeatEnd === 'until' ? schema.required('End date is required.') : schema.notRequired()
    ),
    repeatEndTimes: Yup.number().when('repeatEnd', ([repeatEnd] : any, schema) =>
        repeatEnd === 'after' ? schema.min(1).max(50).required('The number of times to repeat is required.') : schema.notRequired()
    ),
  });

  const preloadFormData = (formikProps, eventData) => {
    const { setFieldValue } = formikProps;

    // Update simple fields directly
    setFieldValue("goal", eventData.goalId); // Assuming `goalId` needs to be converted to `goal
    setSelectedGoalId(eventData.goalId);
    setFieldValue("goalStep", eventData.goalStepId);
    setSelectedGoalStepId(eventData.goalStepId);
    setFieldValue("goalStepActivity", eventData.goalStepActivityId);
    setSelectedActivityId(eventData.goalStepActivityId);
    setFieldValue("customActivity", eventData.customActivity || '');
    setFieldValue("customActivityDescription", eventData.customActivityDescription || '');

    // Convert the date and time if necessary and update
    console.log(eventData.schedule.isFullDayEvent);
    if (eventData.schedule.isFullDayEvent == 1)
    {
      setFieldValue("allDay", true);
    }
    else
    {
      setFieldValue("allDay", false);
    }
    // setFieldValue("allDay", eventData.schedule.isFullDayEvent);
    setFieldValue("selectDate", formatDate(eventData.scheduledDatetime)); // You'll need to implement formatDate
    if (!eventData.allDay) {
      setFieldValue("selectTime", formatTime(eventData.scheduledDatetime)); // Implement formatTime accordingly
    }

    // Handling repeating events
    setFieldValue("repeat", eventData.repeat ? 'repeat' : 'doNotRepeat'); // Adjust based on how `repeat` is determined
    if (eventData.repeat) {
      setFieldValue("repeatInterval", eventData.repeatInterval);
      setFieldValue("repeatFrequency", eventData.repeatFrequency);
      setFieldValue("repeatEnd", determineRepeatEnd(eventData)); // Implement logic to determine 'until', 'after', or another value
      if (eventData.repeatEnd === 'until') {
        setFieldValue("repeatEndDate", eventData.repeatEndDate);
      } else if (eventData.repeatEnd === 'after') {
        setFieldValue("repeatEndTimes", eventData.repeatEndTimes);
      }
    }
    // Add any additional fields as necessary
  };
// Extracts the date part in YYYY-MM-DD format
  const formatDate = (datetime) => {
    return datetime.slice(0, 10); // Extracts the date part
  };

// Extracts the time part in HH:MM format, assuming the time is in UTC and needs to be converted to local
  const formatTime = (datetime) => {
    const date = new Date(datetime);
    // Formats hours and minutes to ensure they are always two digits
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const determineRepeatEnd = (eventData) => {
    if (eventData.repeatEndDate) {
      return 'until';
    } else if (eventData.repeatEndTimes) {
      return 'after';
    }
    return 'doNotRepeat'; // Fallback or default case
  };

  // category validation
  const categoryValidation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      isCustomActivitySelected: false,
      goal: '', // Assuming goal is the name of the goal field
      goalStep: '',
      goalStepActivity: '',
      customActivity: '',
      customActivityDescription: '',
      selectDate: '',
      selectTime: '',
      allDay: false,
      repeat: '',
      repeatInterval: '1',
      repeatFrequency: 'Day(s)',
      repeatEnd: '',
      repeatEndDate: '',
      repeatEndTimes: '1',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      setIsLoading(true); // Start loading

      const localDateTime = new Date(`${values.selectDate} ${values.selectTime}`);
      const utcDateTime = localDateTime.toISOString().replace('T', ' ');

      if (isEdit) {

        let updateEvent: UpdateEvent = {
          newScheduledDatetime: values.allDay ? `${values.selectDate} 00:00:00Z` : utcDateTime, // Using template literals for clarity
          // The rest of the properties are added conditionally below
        };
        if (values.repeat && values.repeat !== "dontRepeat") {
          const frequencyMapping: { [key: string]: NewEvent['frequency'] } = {
            "Day(s)": "DAILY",
            "Week(s)": "WEEKLY",
            "Month(s)": "MONTHLY",
            // Add other mappings as necessary
          };

          // updateEvent.frequency = frequencyMapping[values.repeatFrequency];
          // updateEvent.interval = parseInt(values.repeatInterval, 10) || undefined;

          if (values.repeatEnd === "until" && values.repeatEndDate) {
            updateEvent.repetitionEndDate = values.repeatEndDate;
          } else if (values.repeatEnd === "after" && values.repeatEndTimes) {
            updateEvent.count = parseInt(values.repeatEndTimes, 10);
          }
          // You can add logic for weekdays and dayOfMonth here if your UI/form supports selecting them
        }

        if (updateEvent.frequency == 'WEEKLY') {
          // The indices represent days of the week starting from Monday (0) to Sunday (6)
          const dayLetters = [0, 1, 2, 3, 4, 5, 6];

          const selectedDayLetters = selectedDays
              .map((isSelected, index) => isSelected ? dayLetters[index] : null)
              .filter(letter => letter !== null);

// Convert array of numbers to a string where each number is concatenated without duplicates
          updateEvent.weekdays = selectedDayLetters.join('');

        }
        console.log(event, originalEvent);
        if (originalEvent)
        {
          // update event
          dispatch(onUpdateEvent({clientId: selectedClientId, scheduleId: event.id, eventId: originalEvent.eventId, event: updateEvent}));

        }

        categoryValidation.resetForm();
      } else {

        let goalStepActivity = values.goalStepActivity;

        if (selectedClientId && selectedGoalId && selectedGoalStepId && goalStepActivity == 'custom') {
          const response =   await addNewCustomActivity(selectedClientId, selectedGoalId, selectedGoalStepId, values.customActivity, values.customActivityDescription);
          goalStepActivity = response.data.id;
          setIsCustomActivitySelected(false);
        }

        let newEvent: NewEvent = {
          goalStepActivityId: parseInt(goalStepActivity, 10),
          scheduledDatetime: values.allDay ? `${values.selectDate} 00:00:00Z` : utcDateTime, // Using template literals for clarity
          isFullDayEvent: values.allDay ? 1 : 0,
          // The rest of the properties are added conditionally below
        };

// Conditional logic to add optional properties based on user input
        if (values.repeat && values.repeat !== "dontRepeat") {
          const frequencyMapping: { [key: string]: NewEvent['frequency'] } = {
            "Day(s)": "DAILY",
            "Week(s)": "WEEKLY",
            "Month(s)": "MONTHLY",
            // Add other mappings as necessary
          };

          newEvent.frequency = frequencyMapping[values.repeatFrequency];
          newEvent.interval = parseInt(values.repeatInterval, 10) || undefined;

          if (values.repeatEnd === "until" && values.repeatEndDate) {
            newEvent.repetitionEndDate = values.repeatEndDate;
          } else if (values.repeatEnd === "after" && values.repeatEndTimes) {
            newEvent.count = parseInt(values.repeatEndTimes, 10);
          }
          // You can add logic for weekdays and dayOfMonth here if your UI/form supports selecting them
        }

        if (newEvent.frequency == 'WEEKLY') {
          // The indices represent days of the week starting from Monday (0) to Sunday (6)
          const dayLetters = [0, 1, 2, 3, 4, 5, 6];

          const selectedDayLetters = selectedDays
              .map((isSelected, index) => isSelected ? dayLetters[index] : null)
              .filter(letter => letter !== null);

// Convert array of numbers to a string where each number is concatenated without duplicates
          newEvent.weekdays = selectedDayLetters.join('');

        }

        // save new eventdispatch(addNewEvent({ clientId, event: newEvent }));
        dispatch(onAddNewEvent({clientId: selectedClientId, event: newEvent}));


        categoryValidation.resetForm()
      }
      toggle();
      setIsLoading(false);
    }
    // End loading
  });



  useEffect(() => {
    if (!modalCategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modalCategory, event, isEdit]);

  /**
   * Handling the modal state
   */


  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg: any) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);

    // Format the date and time for the form inputs
    const formattedDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMin.toString().padStart(2, '0')}`;

    // Update Formik's values - Assuming you have access to setFieldValue from Formik's context or props
    categoryValidation.setFieldValue("selectDate", formattedDate);
    categoryValidation.setFieldValue("selectTime", formattedTime);

    setSelectedActivityId(null);
    setSelectedGoalStepId(null);
    setSelectedGoalId(null);



    toggle();
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg: any) => {
    const event = arg.event;
    const originalEvent = event.extendedProps.originalEvent;
    console.log(event, originalEvent);
    const formattedStartDate = new Date(event.start).toISOString().slice(0, 19).replace('T', ' ') + 'Z';

    setEvent({
      id: event.id,
      title: event.title,
      payload: event.title, // Assuming this is intentional
      // Consider retrieving title_category from originalEvent if it's stored there
      start: formattedStartDate, // Adjusted start date
      className: event.classNames,
      category: event.classNames[0], // Assuming the first className is the category
      event_category: event.classNames[0], // Same assumption as above

      // Additional fields from originalEvent (if applicable)
      scheduleId: originalEvent?.scheduleId,
      goalStepActivityId: originalEvent?.goalStepActivityId,
      // Add more fields as necessary based on what you need in your modal
    });

    preloadFormData(categoryValidation, originalEvent);

    setOriginalEvent(originalEvent);

    const schedule = originalEvent.schedule;

    if (schedule)
    {
      if (schedule.count > 1 || schedule.interval > 1 || schedule. repetitionEndDate != null)
      {
        setIsRepeating(true);
        setDeleteId(originalEvent.scheduleId);
      }
      else
      {
        setIsRepeating(false);
        setDeleteId(originalEvent.eventId);
      }

    }

    console.log(originalEvent);




    setIsEdit(true);
    setModalCategory(true)
    toggle();
  };

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {

    // selectedClientId
    if (deleteId && isRepeating && deleteRepeatingModal) {
      dispatch(onDeleteAllEvents({ clientId: selectedClientId, scheduleId: deleteId }));
      setDeleteRepeatingModal(false);
    }
    else if (originalEvent)
    {
      dispatch(onDeleteEvent({ clientId: selectedClientId, scheduleId: originalEvent.scheduleId, eventId: originalEvent.eventId }));
      setDeleteModal(false);
    }

  };

  /**
   * On category darg event
   */
  const onDrag = (event: any, category: any) => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = (event: any) => {
    const date = event['date'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf("fc-event-draggable") === -1) {
      const modifiedData: any = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      dispatch(onAddNewEvent(modifiedData));
    }
  };

  //set the local language
  const enLocal: object = {
    "code": "en-nz",
    "week": {
      "dow": 1,
      "doy": 4
    },
    "buttonHints": {
      "prev": "Previous $0",
      "next": "Next $0",
      "today": "This $0"
    },
    "viewHint": "$0 view",
    "navLinkHint": "Go to $0"
  };
  const [isLocal] = useState<any>(enLocal);
  const handlePrint = () => {

    if (calendarRef.current) {
      const calendarEl = calendarRef.current; // Now TypeScript knows this is an HTMLElement
      const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');


      if (printWindow) {
        // Dynamically write the document content
        printWindow.document.write('<html><head><title>Print Calendar</title>');
        // Include FullCalendar CSS
        printWindow.document.write('<link rel="stylesheet" href="path/to/fullcalendar/main.css">');
        // Include Bootstrap CSS if necessary
        printWindow.document.write('<link rel="stylesheet" href="path/to/bootstrap/css/bootstrap.min.css">');
        // Include your custom CSS
        printWindow.document.write('<link rel="stylesheet" href="/path/to/your/custom/styles.css">');
        printWindow.document.write('</head><body>');
        printWindow.document.write(calendarEl.outerHTML); // Copy the calendar HTML
        printWindow.document.write('</body></html>');

        printWindow.document.close();

        setTimeout(() => {
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        }, 250);
      }
    }
  }

  const handleGoalChange = (e) => {
    const selectedGoalId = e.target.value;

  console.log(selectedGoalId);

    const { value } = e.target;
    categoryValidation.setFieldValue("goal", value);


    categoryValidation.setFieldValue("isCustomActivitySelected", false);
    setIsCustomActivitySelected(false);
    setSelectedGoalId(selectedGoalId == '' ? null : selectedGoalId);
    setSelectedGoalStepId(null);
    setSelectedActivityId(null);
    // Assuming you have a state to track the selected goal

  };
  const handleGoalStepChange = (e) => {
    const selectedGoalStepId = e.target.value;
    const { value } = e.target;
    categoryValidation.setFieldValue("goalStep", value);
    categoryValidation.setFieldValue("isCustomActivitySelected", false);
    setIsCustomActivitySelected(false);
    setSelectedActivityId(null);
    setSelectedGoalStepId(selectedGoalStepId == '' ? null : selectedGoalStepId); // Assuming you have a state to track the selected goal

  };
  const handleActivityChange = (e) => {
    const { value } = e.target;
    categoryValidation.setFieldValue("goalStepActivity", value);
    setSelectedActivityId(value);
    // Check if the selected value is 'custom'
    if (value === 'custom') {
      setIsCustomActivitySelected(true);
      categoryValidation.setFieldValue("isCustomActivitySelected", true);

    } else {
      setIsCustomActivitySelected(false);
      categoryValidation.setFieldValue("isCustomActivitySelected", false);

    }
  };
  const handleDateChange = (event) => {
    categoryValidation.setFieldValue("selectDate", event.target.value);
  };

  const handleTimeChange = (event) => {
    categoryValidation.setFieldValue("selectTime", event.target.value);
  };
  function renderEventContent(eventInfo) {
    return (
        <div className="fc-event-title">
          <b>{eventInfo.timeText}</b> - <span>{eventInfo.event.title}</span>
          {/* You can add more elements here as needed */}
        </div>
    );
  }

  return (
      <React.Fragment>
        <ToastContainer />
        <DeleteModal
            show={deleteRepeatingModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteRepeatingModal(false)}
            isRepeating={true}
      />
        <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteEvent}
            onCloseClick={() => setDeleteModal(false)}
        />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Calendar" breadcrumbItem={`${clientTitle} Calendar`}/>
          <Row>
            <Col className="col-12">
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      <Col xs={12}>
                        <Button color="secondary" onClick={handlePrint}>Print Calendar</Button>
                      </Col>
                      <br/>
                      <Col xs={12}>
                        <p className="text-muted">
                          Select an event state:
                        </p>
                        <Col className="btn-group" role="status">
                          <input type="radio" className="btn-check" name="status" id="accepted" autoComplete="off"
                                 defaultChecked onClick={() => setCurrentStatusCompleted(false)}/>
                          <label className="btn btn-outline-secondary" htmlFor="accepted">Active</label>

                          <input type="radio" className="btn-check" name="status" id="notaccepted"
                                 autoComplete="off" onClick={() => setCurrentStatusCompleted(true)}/>
                          <label className="btn btn-outline-secondary" htmlFor="notaccepted">Completed</label>
                        </Col>
                      </Col>


                      <div id="external-events" className="mt-2">

                        <div>
                          {selectedClientName ?
                              <p className="table-bordered">Current Selected {clientTitle}: <b>{selectedClientName}</b></p> :
                              <p className="text-muted">
                                Select a {clientTitleSingular.toLowerCase()} from a list
                              </p>
                          }
                        </div>
                        <TableContainer
                            columns={columns}
                            data={clientsData || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder={`Search ${clientTitle.toLowerCase()}...`}
                            pagination="pagination"
                            paginationWrapper='dataTables_paginate paging_simple_numbers'
                            divClassName="full-width-container"
                            tableClass="table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        />
                      </div>

                      <Row className="justify-content-center mt-5">
                        <img src={verification} alt="" className="img-fluid d-block"/>
                      </Row>
                      <Row>
                          <div style={{flexDirection: "row"}}>
                            <div className="bg-info" style={{padding: 5, textAlign: "center", fontWeight: "bold", borderRadius: 3}}>
                              <a className="text-white">{clientTitleSingular}-Scheduled Activity</a>
                            </div>
                            <div className="bg-success" style={{padding: 5, textAlign: "center", fontWeight: "bold", borderRadius: 3}}>
                              <a className="text-white">{managerTitleSingular}-Scheduled Activity</a>
                            </div>
                          </div>
                      </Row>
                    </CardBody>
                  </Card>

                </Col>

                <Col className="col-xl-9">
                  {/* fullcalendar control */}
                  <Card>
                    <CardBody>
                      <div ref={calendarRef}>
                        <FullCalendar
                            id="calendar"
                            plugins={[
                              BootstrapTheme,
                              dayGridPlugin,
                              listPlugin,
                              interactionPlugin,
                            ]}
                            firstDay={0}
                            initialView="dayGridMonth"
                            slotDuration={"00:15:00"}
                            handleWindowResize={true}
                            themeSystem="bootstrap"
                            locale={isLocal}
                            headerToolbar={{
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                            }}
                            events={memoizedEvents}
                            editable={true}
                            droppable={true}
                            selectable={true}
                            dateClick={handleDateClick}
                            eventClick={handleEventClick}
                            eventContent={renderEventContent}
                            drop={onDrop}
                            datesSet={(info) => {
                              const start = info.view.currentStart.getMonth();
                              const end = info.view.currentEnd.getMonth();

                              if (start < end) {
                                setIsSameMonth(false);
                              }
                              else {
                                setIsSameMonth(true);
                              }
                              // This function is called when the calendar's visible date range changes
                              console.log("The current view title is:", info.view.title);
                              // If you specifically need to work with the month as a number or date object for some reason:
                              const currentSelectedMonth = info.view.currentStart.getMonth(); // Gets the month as a number (0-11)
                              const currentYear = info.view.currentStart.getFullYear(); // Gets the year
                              setCurrentMonth(currentSelectedMonth+1);
                              setCurrentYear(currentYear);

                              console.log(info.view.currentStart, info.view.currentEnd, start, end);
                              console.log(`${currentMonth} Current month being viewed: ${currentSelectedMonth + 1}, Year: ${currentYear}`);
                              // Remember, JavaScript months are 0-indexed, so January is 0, February is 1, etc.
                              // If you need the month name:
                              const monthName = info.view.currentStart.toLocaleString('default', { month: 'long' });
                              console.log(`Month name: ${monthName}`);



                            }
                            }
                        />
                      </div>
                    </CardBody>
                  </Card>

                  {/* New/Edit event modal */}
                  <Modal
                    isOpen={modalCategory && !currentStatusCompleted}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle}>
                      <h5 className="modal-title" id="modal-title">
                        {isEdit ? "Edit Goal" : "Add Goal"}
                      </h5>
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          categoryValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label>Goal</Label>
                              <Input
                                  name="goal"
                                  type="select"
                                  placeholder="Insert Event Name"
                                  onChange={handleGoalChange}
                                  onBlur={categoryValidation.handleBlur}
                                  disabled={isEdit}
                                  value={selectedGoalId || ""}
                                  invalid={
                                    categoryValidation.touched.goal && categoryValidation.errors.goal ? true : false
                                  }
                              >
                                <option value="">Select Goal</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id} >{category.title}</option>
                                ))}
                              </Input>
                              {categoryValidation.touched.goal && categoryValidation.errors.goal && (
                                  <FormFeedback type="invalid">{categoryValidation.errors.goal}</FormFeedback>
                              )}
                            </div>
                          </Col>
                          {steps.length > 0 && selectedGoalId && <Col className="col-12">
                            <div className="mb-3">
                              <Label>Goal Step</Label>
                              <Input
                                type="select"
                                name="goalStep"
                                placeholder="All Day Event"
                                disabled={isEdit}
                                onChange={handleGoalStepChange}
                                onBlur={categoryValidation.handleBlur}
                                value={selectedGoalStepId || ""}
                                invalid={
                                  categoryValidation.touched.goalStep && categoryValidation.errors.goalStep ? true : false
                                }
                              >
                                <option value="">Select Goal Step</option>
                                {steps.map(step => (
                                    <option key={step.id} value={step.id}>{step.title}</option>
                                ))}
                              </Input>
                              {categoryValidation.touched.goalStep && categoryValidation.errors.goalStep ? (
                                <FormFeedback type="invalid">{categoryValidation.errors.goalStep}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>}
                          {selectedGoalStepId && activities.length > 0 &&  <Col className="col-12">
                            <div className="mb-3">
                              <Label>Goal Step Activity</Label>
                              <Input
                                  type="select"
                                  name="goalStepActivity"
                                  disabled={isEdit}
                                  onChange={handleActivityChange}
                                  onBlur={categoryValidation.handleBlur}
                                  value={selectedActivityId || ""}
                                  invalid={categoryValidation.touched.goalStepActivity && categoryValidation.errors.goalStepActivity ? true : false}
                              >
                                <option value="">Select Goal Step Activity</option>
                                {activities.map(activity => (
                                    <option key={activity.id} value={activity.id}>{activity.title}</option>
                                ))}

                              </Input>
                              {!isCustomActivitySelected && categoryValidation.touched.goalStepActivity && categoryValidation.errors.goalStepActivity ? (
                                  <FormFeedback type="invalid">{categoryValidation.errors.goalStepActivity}</FormFeedback>
                              ) : null}
                              {/* Conditionally render the extra input field for custom activity name */}
                              {isCustomActivitySelected && (
                                  <div>
                                  <Input
                                      type="text"
                                      name="customActivity"
                                      placeholder="Enter Custom Activity Name"

                                      onChange={categoryValidation.handleChange} // Make sure to handle the value in your form state
                                      onBlur={categoryValidation.handleBlur}
                                      value={categoryValidation.values.customActivity || ""}
                                      className="mt-2" // Add some margin-top for spacing
                                  />
                                <Input
                                    type="textarea"
                                name="customActivityDescription"
                                placeholder="Enter Custom Activity Description"
                                onChange={categoryValidation.handleChange} // Update your form state to include handling for this textarea
                              onBlur={categoryValidation.handleBlur} // Useful for validation on losing focus
                              value={categoryValidation.values.customActivityDescription || ""} // Ensures the textarea is a controlled component
                              className="mt-2 block w-full" // Styling: margin-top for spacing, block display, and full width
                              rows={4} // Sets the initial visible number of lines in the text area
                            />
                                  </div>
                              )}
                              {/* Display validation error if applicable */}
                              {categoryValidation.touched.customActivity && categoryValidation.errors.customActivity ? (
                                  <div className="text-danger">{categoryValidation.errors.customActivity}</div>
                              ) : null}
                            </div>
                          </Col>}
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label for="selectDate">Select Date</Label>
                              <Input
                                  type="date"
                                  name="selectDate"
                                  id="selectDate"
                                  onChange={handleDateChange} // Define this handler to update your state or formik values
                                  onBlur={categoryValidation.handleBlur} // Adjust if using Formik and need to handle blur
                                  value={categoryValidation.values.selectDate || ""} // Adjust based on your state management or Formik values
                              />
                              {/* Display validation feedback if needed */}
                            </div>
                          </Col>
                          {!categoryValidation.values.allDay  && <Col className="col-12">
                            <div className="mb-3">
                              <Label for="selectTime">Select Time</Label>
                              <Input
                                  type="time"
                                  name="selectTime"
                                  id="selectTime"
                                  disabled={categoryValidation.values.allDay} //
                                  onChange={handleTimeChange} // Define this handler to update your state or formik values
                                  onBlur={categoryValidation.handleBlur} // Adjust if using Formik and need to handle blur
                                  value={categoryValidation.values.selectTime || ""} // Adjust based on your state management or Formik values
                              />
                              {/* Display validation feedback if needed */}
                            </div>
                          </Col>}
                          <Col className="col-12">
                            <div className="mb-3">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                      type="checkbox"
                                      name="allDay"
                                      id="allDay"
                                      onChange={categoryValidation.handleChange}
                                      onBlur={categoryValidation.handleBlur}
                                      checked={categoryValidation.values.allDay || false}
                                  />{' '}
                                  Set as All-day event
                                </Label>
                              </FormGroup>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label for="repeat">Repeat</Label>
                              <Input
                                  type="select"
                                  name="repeat"
                                  id="repeat"
                                  disabled={isEdit}
                                  onChange={categoryValidation.handleChange}
                                  onBlur={categoryValidation.handleBlur}
                                  value={categoryValidation.values.repeat || ''}
                                  invalid={categoryValidation.touched.repeat && categoryValidation.errors.repeat ? true : false}
                              >
                                <option value="">Select Option</option>
                                <option value="repeat">Repeat</option>
                                <option value="dontRepeat">Don't Repeat</option>
                              </Input>
                              {categoryValidation.touched.repeat && categoryValidation.errors.repeat ? (
                                  <FormFeedback type="invalid">{categoryValidation.errors.repeat}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {categoryValidation.values.repeat === 'repeat' && (
                              <div>
                                <div className="d-flex align-items-center mb-3">
                                  <Label className="me-2">Every</Label>
                                  <Input
                                      type="select"
                                      name="repeatInterval"
                                      onChange={categoryValidation.handleChange}
                                      onBlur={categoryValidation.handleBlur}
                                      value={categoryValidation.values.repeatInterval || ""}

                                      className="me-2" // Add some spacing
                                  >
                                    {generateIntervalOptions()}
                                  </Input>

                                  <Input
                                      type="select"
                                      name="repeatFrequency"
                                      onChange={categoryValidation.handleChange}
                                      onBlur={categoryValidation.handleBlur}
                                      value={categoryValidation.values.repeatFrequency || ""}
                                  >
                                    <option value="Day(s)">Day(s)</option>
                                    <option value="Week(s)">Week(s)</option>
                                    <option value="Month(s)">Month(s)</option>
                                  </Input>
                                </div>
                                {categoryValidation.values.repeatFrequency === 'Week(s)' && (
                                    <div className="d-flex align-items-center justify-content-end">
                                      {days.map((day, index) => (
                                          <div
                                              key={day}
                                              className={`day-circle ${selectedDays[index] ? 'selected' : ''} ${index === adjustedIndex ? 'current-day' : ''}`}
                                              onClick={() => handleDayChange(index)}
                                              // Prevent interaction if it's the current day
                                              style={index === adjustedIndex ? { pointerEvents: "none" } : {}}
                                          >
                                            {day}
                                          </div>
                                      ))}
                                    </div>
                                )}



                              </div>


                          )}
                          {categoryValidation.values.repeat === 'repeat' && (   <Col className="col-12">
                            <div className="mb-3">
                              <Label for="repeatEnd">Repeat Options</Label>
                              <Input
                                  type="select"
                                  name="repeatEnd"
                                  id="repeatEnd"

                                  onChange={categoryValidation.handleChange}
                                  onBlur={categoryValidation.handleBlur}
                                  value={categoryValidation.values.repeatEnd || ""}
                                  invalid={categoryValidation.touched.repeatEnd && categoryValidation.errors.repeatEnd ? true : false}
                              >
                                <option value="">Select Option</option>
                                <option value="until">Until</option>
                                <option value="after">After</option>
                              </Input>
                              {categoryValidation.touched.repeatEnd && categoryValidation.errors.repeatEnd ? (
                                  <FormFeedback type="invalid">{categoryValidation.errors.repeatEnd}</FormFeedback>
                              ) : null}
                            </div>

                            {categoryValidation.values.repeatEnd === 'until' && (
                                <div className="mb-3">
                                  <Label for="repeatEndDate">Select Date</Label>
                                  <Input
                                      type="date"
                                      name="repeatEndDate"
                                      id="repeatEndDate"

                                      onChange={categoryValidation.handleChange}
                                      onBlur={categoryValidation.handleBlur}
                                      value={categoryValidation.values.repeatEndDate || ""}
                                      invalid={categoryValidation.touched.repeatEndDate && categoryValidation.errors.repeatEndDate ? true : false}
                                  />
                                  {categoryValidation.touched.repeatEndDate && categoryValidation.errors.repeatEndDate ? (
                                      <FormFeedback type="invalid">{categoryValidation.errors.repeatEndDate}</FormFeedback>
                                  ) : null}
                                </div>
                            )}

                            {categoryValidation.values.repeatEnd === 'after' && (
                              <>
                                <div className="mb-3 d-flex align-items-center">
                                  <Input
                                      type="select"
                                      name="repeatEndTimes"
                                      id="repeatEndTimes"

                                      onChange={categoryValidation.handleChange}
                                      onBlur={categoryValidation.handleBlur}
                                      value={categoryValidation.values.repeatEndTimes || ""}
                                      className="me-2"
                                      invalid={categoryValidation.touched.repeatEndTimes && categoryValidation.errors.repeatEndTimes ? true : false}
                                  >
                                    {Array.from({ length: 50 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    ))}
                                  </Input>
                                  <span>time(s)</span>
                                </div>
                                {categoryValidation.touched.repeatEndTimes && categoryValidation.errors.repeatEndTimes ? (
                                    <FormFeedback type="invalid">{categoryValidation.errors.repeatEndTimes}</FormFeedback>
                                ) : null}
                              </>
                            )}
                          </Col>)}

                        </Row>

                        <Row className="mt-2">
                          <Col className="col-8">
                            {isEdit &&
                                <button type="button" className="btn btn-danger font-size-12  me-1" id="btn-delete-event" onClick={() => {
                                  toggle();
                                  setDeleteModal(true)
                                }}>Delete</button>
                            }
                            {isEdit && isRepeating &&
                                <button type="button" className="btn btn-warning font-size-12" id="btn-delete-event-2" onClick={() => {
                                  toggle();
                                  setDeleteRepeatingModal(true)
                                }}>Delete Scheduled Activities</button>
                            }
                          </Col>

                          <Col className="col-4 text-end">
                            <button
                                type="button"
                                className="btn btn-light me-1"
                                onClick={toggle}
                            >
                              Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                id="btn-save-event"
                                disabled={isLoading} // Disable button when loading
                            >
                              {isLoading ? <span className="spinner-border spinner-border-sm" role="status"
                                                 aria-hidden="true"></span> : 'Save'}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                  {originalEvent && (
                      <ViewGoalModal
                          originalEvent={originalEvent}
                          isOpen={modalCategory && currentStatusCompleted}
                          toggle={toggle}
                      />
                  )}



                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Calender;
