import { APIClient, setAuthorization } from "./api_helper";

import * as url from "./url_helper";

import config from "env.config";
import { setXApiKey } from "./api_helper";
import {UserRoleKeys, userManager} from "../utils/UserManager";
import {GET_RESOURCE_CATEGORIES, ROLES} from "./url_helper";
import { readFileSync } from "fs";
import axios from "axios";
import {cl} from "@fullcalendar/core/internal-common";
const api = new APIClient();



function dynamicallyRemoveFields(data, fieldsToRemove) {
    let result = { ...data };
    for (const field of fieldsToRemove) {
        delete result[field];
    }
    return result;
}

// Usage
const fieldsToOmit = ['id', 'createdAt', 'updatedAt', 'deletedAt', 'lastModifiedBy', 'programId', 'goalId', 'goalStepId'];
//Profile
export const getMe = () => api.get(url.GET_ME);
//Invitations
export const getInvitations = (role: UserRoleKeys = UserRoleKeys.RoleNone, tenantFilterId?: string, status?: number, offset: number = 0, limit: number = 20) => {
  const tenantId = userManager.getUserTenant(); // Assuming tenantId is part of userData
  const currentUserRole = userManager.getUserRole();

  const groupIds = userManager.getUser()?.dbUser.groupIds

  const params = {
      role: role,
      status: status,  // If status is undefined, it won't be included in the query string
      offset: offset,
      limit: limit
  };

  if (!(currentUserRole === UserRoleKeys.RoleCorp && role === UserRoleKeys.RoleAdmin)) {
    params['tenantId'] = tenantId
  }

  if (tenantFilterId) {
    params['tenantId'] = tenantFilterId
  }

  if (currentUserRole === UserRoleKeys.RoleManager) {
    params['groupId'] = groupIds?.[0]
  }

  return api.get(url.GET_INVITATIONS, params);
};
export const sendNewInvitation = (userRole: UserRoleKeys, newInvitation: any, tenant_dbName: (string | null) = null) => {
  const tenantId = userManager.getUserTenant(); // Assuming tenantId is part of userData
  const currentUserRole = userManager.getUserRole();
  const groupIds = userManager.getUser()?.dbUser.groupIds

  const params ={
    role: userRole,
    tenantId: tenant_dbName || tenantId
  };

  const queryString = params
  ? Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
  : '';

  if (currentUserRole === UserRoleKeys.RoleManager) {
    newInvitation['groupId'] = groupIds?.[0]
  }

  return api.create(`${url.NEW_INVITE}${queryString ? `?${queryString}` : ''}`, newInvitation);
};

export const resendInvitation = (role: UserRoleKeys, inviteId: number, requestBody = {}, tenant_dbName: (string | null) = null) => {
  const tenantId = userManager.getUserTenant(); // Assuming tenantId is part of userData

  const params = {
    role: role
  }

  if (tenant_dbName) {
    params['tenantId'] = tenant_dbName
  }

  const queryString = params
  ? Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
  : '';

  return api.put(`${url.NEW_INVITE}/${inviteId}${queryString ? `?${queryString}` : ''}`, requestBody)
}

export const getManagers = (status?: number, offset: number = 0, limit: number = 20) => {
    const params = {
        offset: offset,
        limit: limit
    };

    return api.get(url.GET_MANAGERS, params);
};

export const updateManager = async (manager: any) => {
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId'];
    const cleanManagerData = dynamicallyRemoveFields(manager, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_MANAGERS}/${manager.id}`, cleanManagerData)};

export const deleteManager = (managerId: number) => {
  return api.delete(`${url.DELETE_MANAGERS}/${managerId}`);
}

export const getCorps = (status?: number, offset: number = 0, limit: number = 20) => {
    const params = {
        offset: offset,
        limit: limit
    };

    return api.get(url.GET_CORPS, params);
};
export const updateCorpAdmin = (corpId: any, data: any) => {
  return api.put(`${url.GET_CORPS}/${corpId}`, data);
}
export const deleteCorpAdmin = (corpId: number) => {
  return api.delete(`${url.CORP_ADMINS}/${corpId}`)
};
export const getAdmins = (tenantFilterId?: string, status?: number, offset: number = 0, limit: number = 20) => {
    const params = {
        offset: offset,
        limit: limit
    };

    if (tenantFilterId) {
      params['tenantId'] = tenantFilterId
    }

    return api.get(url.GET_ADMINS, params);
};

export const updateAdmin = async (admin: any, tenantId: string) => {
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId'];
    const cleanAdminData = dynamicallyRemoveFields(admin, fieldsToOmitMore);
  return await api.put(`${url.UPDATE_ADMINS}/${admin.id}?tenantId=${tenantId}`, cleanAdminData);
}

export const deleteAdmin = (adminId: number, tenantId: string) => {
  return api.delete(`${url.DELETE_ADMINS}/${adminId}?tenantId=${tenantId}`);
}
export const deleteInvite = (inviteId: number, role: string, tenantId: string) => {

    if (role === UserRoleKeys.RoleCorp)
    {
        return api.delete(`${url.DELETE_INVITE}/${inviteId}?role=${role}`);
    }


    return api.delete(`${url.DELETE_INVITE}/${inviteId}?role=${role}&tenantId=${tenantId}`);
}
export const getClients = (status?: number, offset: number = 0, limit: number = 20 , groupId : number | null = null ) => {
    const params = {
        offset: offset,
        limit: limit,
    };

    if (groupId)
    {
        params["groupId"] = groupId;
    }
    return api.get(url.GET_CLIENTS, params);
};
export const updateClient = async (client: any) => {
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId'];
    const cleanClientData = dynamicallyRemoveFields(client, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_CLIENTS}/${client.id}`, cleanClientData)};
export const deleteClient = (clientId: number) => {
  return api.delete(`${url.DELETE_CLIENTS}/${clientId}`);
}

export const getLocations = (status?: number, offset: number = 0, limit: number = 20) => {
    const params = {
        offset: offset,
        limit: limit
    };

    return api.get(url.GET_LOCATIONS, params);
};

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data)
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);


// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message: any;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);



export const getEvents = () => api.get(url.GET_EVENTS, {'month':2, 'year':2024});
export const getEventsApi = (clientId , params) => {
    // Replace {goalId} placeholder with the actual goalId/clients/1024/schedules/events
    const endpoint = `/clients/${clientId}/schedules/events`;
    return api.get(endpoint , params);
};

export const getMoreEventsApi = ( params) => {
    // Replace {goalId} placeholder with the actual goalId/clients/1024/schedules/events
    const endpoint = `/clients/schedules`;
    return api.get(endpoint , params);
};
// api.get Events
export const getCategories = () => api.get(url.GET_CATEGORIES);
export const getGoalStepsApi = (goalId) => {
    // Replace {goalId} placeholder with the actual goalId
    const endpoint = `/goals/${goalId}/goal-steps`;
    return api.get(endpoint);
};

export const getGoalStepActivitiesApi = (goalId, goalStepId) => {
    // Replace both {goalId} and {goalStepId} placeholders with the actual values
    const endpoint = `/goals/${goalId}/goal-steps/${goalStepId}/activities`;
    return api.get(endpoint);
};

export const getClientGoalStepActivitiesApi = (clientId, goalId, goalStepId) => {
    // Construct the endpoint URL including clientId, goalId, and goalStepId
    const endpoint = `/clients/${clientId}/goals/${goalId}/goal-steps/${goalStepId}/activities`;
    return api.get(endpoint);
};

// add Events
export const addNewEvent = (clientId, event) => {
    // Dynamically construct the endpoint URL using clientId
    const endpoint = `/clients/${clientId}/schedules`;

    // Assuming api.create is used for making POST requests
    // Replace api.create with api.post if your API service uses a different method name for POST requests
    return api.create(endpoint, event);
};
// Function to add a new activity to a goal step for a specific client and goal
interface Activity {
    title: string;
    description?: string | null; // Make description optional and allow it to be null
}

// Assuming api.create is used for making POST requests
// You might need to replace api.create with api.post if your API uses a different method name for POST requests.

export const addNewCustomActivity = (clientId: string, goalId: string, goalStepId: string, title: string, description?: string | null): Promise<any> => {
    const endpoint = `/clients/${clientId}/goals/${goalId}/goal-steps/${goalStepId}/activities`;

    const activity: Activity = { title };
    if (description !== null && description !== undefined && description !== '') {
        activity.description = description;
    }

    return api.create(endpoint, activity);
};



// update Event
export const updateEvent = (clientId, scheduleId, eventId, event) => {
    // Construct the endpoint URL dynamically using clientId and scheduleId
    const endpoint = `/clients/${clientId}/schedules/${scheduleId}/events/${eventId}`;

    // Correct method to update an event, assuming you want to replace or update the resource

    return api.put(endpoint, event);
};
// delete Event
export const deleteEvent = (clientId, scheduleId) => {
    // Construct the endpoint URL dynamically using clientId and scheduleId
    const endpoint = `/clients/${clientId}/schedules/${scheduleId}`;

    // Call the delete method on the API, no need to pass event in headers
    return api.delete(endpoint);
};
export const deleteScheduleEvent = (clientId, scheduleId, eventId) => {
    // Construct the endpoint URL dynamically using clientId, scheduleId, and eventId
    const endpoint = `/clients/${clientId}/schedules/${scheduleId}/events/${eventId}`;

    // Call the delete method on the API, no need to pass event in headers
    return api.delete(endpoint);
};

// api.get Messages
export const getMessages = (roomId: any) => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = (message: any) => api.create(url.ADD_MESSAGE, message);

export const deleteMessage = (event: any) => api.delete(url.DELETE_MESSAGE, { headers: { event } });

// email
export const getMailsListsId = ((id: string) => api.get(`${url.GET_MAILS_ID}/${id}`, { params: { id } }))
export const getMailsLists = () => api.get(url.GET_INBOX_MAILS);
export const getSelectFolders = () => api.get(url.SELECT_FOLDER);
export const getselectedmails = (selectEmailId: any) => api.create(url.GET_SELECTE_MAIL, { params: selectEmailId });
export const getUpdateMail = (mail: any) => api.put(url.UPDATE_MAIL, mail);
export const setFolderOnSelectedMails = (data: any) => api.delete(url.SET_FOLDER_SELECTED_MAILS, { headers: { data } })
export const staredMail = (mail: any) => api.delete(url.STARED_MAIL, { headers: { mail } })
export const trashMail = (mail: any) => api.delete(url.TRASH_MAIL, { headers: { mail } })

// delete Mail
export const deleteMail = (mail: any) => api.delete(url.DELETE_MAIL, { headers: { mail } });

// Ecommerce
// api.get Products
export const getProducts = () => api.get(url.GET_PRODUCTS);

// api.get ProductDetails
export const getProductDetail = (id: number) =>
    api.get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

//cart
export const getCart = () => api.get(url.GET_CART);
export const deleteCart = (cart: any) => api.delete(url.DELETE_CART, { headers: { cart } });

// get shops
export const getShops = () => api.get(url.GET_SHOPS);

// api.get Orders
export const getOrders = () => api.get(url.GET_ORDERS);

// add Order
export const addNewOrder = (order: any) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order: any) => api.put(url.UPDATE_ORDER, order);

// delete Order
export const deleteOrder = (order: any) => api.delete(url.DELETE_ORDER, { headers: { order } });

// api.get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// add Customers
export const addNewCustomer = (customer: any) => api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer: any) => api.put(url.UPDATE_CUSTOMER, customer);

// delete Customers
export const deleteCustomer = (customer: any) => api.delete(url.DELETE_CUSTOMER, { headers: { customer } });

// get wallet
export const getWallet = () => api.get(url.GET_WALLET);
//get wallwr / activities
export const getWalletActivities = () => api.get(url.GET_WALLET_ACTIVITIES);

// get tasks
export const getTasks = () => api.get(url.GET_TASKS);
export const addNewTasks = (data: any) => api.create(url.ADD_TASKS, data)
export const updateTasks = (data: any) => api.put(url.UPDATE_TASKS, data)
export const deleteTasks = (data: any) => api.delete(url.DELETE_TASKS, { headers: { data } })

//// get contacts
export const getUsers = () => api.get(url.GET_USERS);

// add User
export const addNewUser = (user: any) => api.create(url.ADD_NEW_USERS, user);

// update User
export const updateUser = (user: any) => api.put(url.UPDATE_USERS, user);


//Delete Contact User
export const deleteUsers = (users: any) => api.delete(url.DELETE_USERS, { headers: { users } });

export const getUserProfile = () => api.get(url.GET_USER_PROFILE);

export const getEarningChartsData = (month: any) => api.get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

export const getTopSellingData = (month: any) => api.get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

// CRM
// api.get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);

// Order List
export const getCryptoOrderList = () => api.get(url.GET_CRYPTO_ORDRER_LIST);

// Invoice
//api.get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES);

export const getInvoicesDetail = (id: number) => api.get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => api.get(url.GET_PROJECTS);

/** PROJECT */
// update user
export const updateProject = (project: any) => api.put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = (project: any) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// get project details
export const getProjectsDetail = (id: any) =>
    api.get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// dashbaord
//Email Chart
export const getDashboardEmailChart = (chartType: any) => api.get(`${url.GET_DASHBOARD_EMAILCHART}/${chartType}`, { param: chartType });

//latest transaction
export const getTransaction = () => api.get(url.GET_TRANSACTION);

// Wallet Balance
export const getWalletBalance = (roomId: any) => api.get(`${url.GET_WALLENT_BALANCE}/${roomId}`, { params: { roomId } });

// Visitors
export const getVisitors = (roomId: any) => api.get(`${url.GET_BLOG_VISITORS}/${roomId}`, { params: { roomId } });

// statistics Applications
export const getStatisticsApplications = (roomId: any) => api.get(`${url.GET_STATISTICS_APPLICATION}/${roomId}`, { params: { roomId } });

// get jobs
export const getJobList = () => api.get(url.GET_JOB_LIST);
// delete jobs
export const deleteJobList = (jobs: any) => api.delete(url.DELETE_JOB_LIST, { headers: { jobs } });
// add jobs
export const addNewJobList = (job: any) => api.create(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = (job: any) => api.put(url.UPDATE_JOB_LIST, job);

// get Apply Jobs
export const getApplyJob = () => api.get(url.GET_APPLY_JOB);
// Delete Apply Jobs
export const deleteApplyJob = (jobs: any) => api.delete(url.DELETE_APPLY_JOB, { headers: { jobs } });

// get chats
export const getChats = () => api.get(url.GET_CHATS);

// // get groups
export const getGroups = () => api.get(url.GET_GROUPS);

//grid job
export const getJobGrid = () => api.get(url.GET_JOB_GRID);

//job Candidate List
export const getJobCandidateList = () => api.get(url.GET_CANDIDATE0_LIST);

// get role-dependent annoucements listing
export const getAnnouncements = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_ANNOUNCEMENTS, params);
};

// create a new announcement
export const addAnnouncement = (announcement: any) => api.create(url.ADD_ANNOUNCEMENTS, announcement);

// modify an announcement
export const updateAnnouncement = async (announcement: any) =>{
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId'];
    const cleanAnnouncementData = dynamicallyRemoveFields(announcement, fieldsToOmitMore);
return await api.put(`${url.UPDATE_ANNOUNCEMENT}/${announcement.id}`, cleanAnnouncementData)};

// remove an announcement
export const deleteAnnouncement = (announcementId: number) => api.delete(`${url.DELETE_ANNOUNCEMENT}/${announcementId}`);


// get all quotes
export const getQuotes = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_QUOTES, params);
};

// create a new daily quote
export const addQuote = (quote: any) => api.create(url.ADD_QUOTE, quote);

// modify daily quote
export const updateQuote = async (quote: any) =>{

    const cleanQouteData = dynamicallyRemoveFields(quote, fieldsToOmit);
    return await api.put(`${url.UPDATE_QUOTE}/${quote.id}`, cleanQouteData)};

// remove an quote
export const deleteQuote = (quoteId: number) => api.delete(`${url.DELETE_QUOTE}/${quoteId}`);

// get all goals
export const getGoals = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_GOALS, params);
};

// get single goal
export const getSingleGoal = (goalId: number) => api.get(`${url.GET_GOALS}/${goalId}`);

// create a new goal
export const addGoal = (goal: any) => api.create(url.ADD_GOAL, goal);

// modify a goal
export const updateGoal = async (goal: any) => {
    const cleanGoalData = dynamicallyRemoveFields(goal, fieldsToOmit);
   return await api.put(`${url.UPDATE_GOAL}/${goal.id}`, cleanGoalData);
}

// remove an goal
export const deleteGoal = (goalId: number) => api.delete(`${url.DELETE_GOAL}/${goalId}`);

// get all goal steps
export const getGoalSteps = (goalId: number, status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(`${url.GOALS}/${goalId}${url.GOALSTEPS}`, params);
};

// get single goal step
export const getSingleGoalStep = (goalId: number, goalStepId: number) => api.get(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}`);

// create a new goal step
export const addGoalStep = (goalId: number, goalStep: any) => api.create(`${url.GOALS}/${goalId}${url.GOALSTEPS}`, goalStep);

// modify a goal step
export const updateGoalStep = async (goalId: number, goalStep: any) =>{
    const cleanGoalStepData = dynamicallyRemoveFields(goalStep, fieldsToOmit);
    return await api.put(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStep.id}`, cleanGoalStepData)
};

// remove an goal step
export const deleteGoalStep = (goalId: number, goalStepId: number) => api.delete(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}`);

// get all goal step activities
export const getGoalStepActivities = (goalId: number, goalStepId: number, status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}`, params);
};

// create a new goal step activities
export const addGoalStepActivity = (goalId: number, goalStepId: number, goalStepActivities: any) => api.create(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}`, goalStepActivities);

// modify a goal step activities
export const updateGoalStepActivity = async (goalId: number, goalStepId: number, goalStepActivities: any) =>{
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId'];
    const cleanActivityData = dynamicallyRemoveFields(goalStepActivities, fieldsToOmitMore);
    return await api.put(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}/${goalStepActivities.id}`, cleanActivityData)};

// remove an goal step activities
export const deleteGoalStepActivity = (goalId: number, goalStepId: number, goalStepActivityId: number) => api.delete(`${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}/${goalStepActivityId}`);

// get all goals
export const getProgramGoals = (programId: number, status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(`${url.PROGRAMS}/${programId}${url.GET_GOALS}`, params);
};

// get single  program goal
export const getSingleProgramGoal = (programId: number, goalId: number) => api.get(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}`);

// create a new goal
export const addProgramGoal = (programId: number, goal: any) => api.create(`${url.PROGRAMS}/${programId}${url.ADD_GOAL}`, goal);

// modify a goal
export const updateProgramGoal = async (programId: number, goal: any) => {
    const cleanGoalData = dynamicallyRemoveFields(goal, fieldsToOmit);
   return await api.put(`${url.PROGRAMS}/${programId}${url.UPDATE_GOAL}/${goal.id}`, cleanGoalData)
};

// remove an goal
export const deleteProgramGoal = (programId: number, goalId: number) => api.delete(`${url.PROGRAMS}/${programId}${url.DELETE_GOAL}/${goalId}`);

// get all goal steps
export const getProgramGoalSteps = (programId: number, goalId: number, status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}`, params);
};

// get single program goal step
export const getSingleProgramGoalStep = (programId: number, goalId: number, goalStepId: number) => api.get(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}`);

// create a new goal step
export const addProgramGoalStep = (programId: number, goalId: number, goalStep: any) => api.create(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}`, goalStep);

// modify a goal step
export const updateProgramGoalStep = async (programId: number, goalId: number, goalStep: any) =>{
    const cleanGoalStepData = dynamicallyRemoveFields(goalStep, fieldsToOmit);
    return await api.put(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStep.id}`, cleanGoalStepData)};

// remove an goal step
export const deleteProgramGoalStep = (programId: number, goalId: number, goalStepId: number) => api.delete(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}`);

// get all goal step activities
export const getProgramGoalStepActivities = (programId: number, goalId: number, goalStepId: number, status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}`, params);
};

// create a new goal step activities
export const addProgramGoalStepActivity = (programId: number, goalId: number, goalStepId: number, goalStepActivities: any) => api.create(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}`, goalStepActivities);

// modify a goal step activities
export const updateProgramGoalStepActivity = async (programId: number, goalId: number, goalStepId: number, goalStepActivities: any) =>{
    const fieldsToOmitMore = [...fieldsToOmit, 'clientId', 'mediaUri'];
    const cleanGoalStepActivityData = dynamicallyRemoveFields(goalStepActivities, fieldsToOmitMore);
   return await api.put(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}/${goalStepActivities.id}`, cleanGoalStepActivityData)};

// remove an goal step activities
export const deleteProgramGoalStepActivity = (programId: number, goalId: number, goalStepId: number, goalStepActivityId: number) => api.delete(`${url.PROGRAMS}/${programId}${url.GOALS}/${goalId}${url.GOALSTEPS}/${goalStepId}${url.GOALACTIVITIES}/${goalStepActivityId}`);

// get all resource-categories
export const getResourceCategories = (status?: number, offset: number = 0, limit: number = 20, resourceType: string | null = null) => {
    const params: Record<string, any> = {
        offset: offset,
        limit: limit
    };

    // Conditionally add resourceType to params if it's not null
    if (resourceType !== null) {
        params.resourceType = resourceType;
    }

    return api.get(url.RESOURCE_CATEGORY,  params );
};



// get single resource category
export const getSingleResourceCategory = (resourceCategoryId: number, resourceType: string | null = null) => {
    // Construct the query string if resourceType is provided
    const params: Record<string, any> = {};

    if (resourceType !== null) {
        params.resourceType = resourceType;
    }

    return api.get(`${url.RESOURCE_CATEGORY}/${resourceCategoryId}`,  params );
};


// create a new resource-category
export const addResourceCategory = (resourceCategory: any) => api.create(url.RESOURCE_CATEGORY, resourceCategory);

// modify a resource-category
export const updateResourceCategory = (resourceCategory: any, resourceCategoryId: number) => api.put(`${url.RESOURCE_CATEGORY}/${resourceCategoryId}`, resourceCategory);

// remove an resource-cateogyr
export const deleteResourceCategory = (categoryId: number) => api.delete(`${url.RESOURCE_CATEGORY}/${categoryId}`);

// get all resources
export const getResources = (categoryId: number, status?: number, offset: number = 0, limit: number = 20, resourceType: string | null = null) => {
  const params: Record<string, any> = {
      offset: offset,
      limit: limit
  };

    // Conditionally add resourceType to params if it's not null
    if (resourceType !== null) {
        params.resourceType = resourceType;
    }

  return api.get(`${url.RESOURCE_CATEGORY}/${categoryId}${url.RESOURCE}`, params);
};

// create a new resource
export const addResource = (categoryId: number, resource: any) => api.create(`${url.RESOURCE_CATEGORY}/${categoryId}${url.RESOURCE}`, resource);

// modify a resource
export const updateResource = async (categoryId: number, resource: any) =>
{
    const cleanResourceData = dynamicallyRemoveFields(resource, fieldsToOmit);
   return await api.put(`${url.RESOURCE_CATEGORY}/${categoryId}${url.RESOURCE}/${resource.id}`, cleanResourceData)
};

// remove an resource
export const deleteResource = (categoryId: number, resourceId: number) => api.delete(`${url.RESOURCE_CATEGORY}/${categoryId}${url.RESOURCE}/${resourceId}`);

// list all programs
export const getPrograms = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.PROGRAMS, params);
};

// create a new program
export const addProgram = (program: any) => api.create(url.PROGRAMS, program);

// modify a program
export const updateProgram = (program: any, programId: number) => api.put(`${url.PROGRAMS}/${programId}`, program);

// remove an program
export const deleteProgram = (programId: number) => api.delete(`${url.PROGRAMS}/${programId}`);

// list all rules
export const getRules = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_RULES, params);
};

// create a new rule
export const addRule = (rule: any) => api.create(url.ADD_RULE, rule);

// modify a rule
export const updateRule = async (rule: any) => {
    const fieldsToOmitMore = [...fieldsToOmit,'ruleGroupIds']
    const cleanRuleData = dynamicallyRemoveFields(rule, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_RULE}/${rule.id}`, cleanRuleData)};

// remove an rule
export const deleteRule = (ruleId: number) => api.delete(`${url.REMOVE_RULE}/${ruleId}`);

// list all rule groups
export const getRuleGroups = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_RULEGROUPS, params);
};

// create a new rule group
export const addRuleGroup = (group: any) => api.create(url.ADD_RULEGROUP, group);

// modify a rule group
export const updateRuleGroup = async (group: any) => {
    const fieldsToOmitMore = [...fieldsToOmit,'ruleGroupIds','rules','ruleIds']
    const cleanRuleGroupData = dynamicallyRemoveFields(group, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_RULEGROUP}/${group.id}`, cleanRuleGroupData)};

// remove an rule group
export const deleteRuleGroup = (groupId: number) => api.delete(`${url.REMOVE_RULEGROUP}/${groupId}`);

// list all all campaigns
export const getCampaigns = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  if (!isNaN(Number(status))) {
    params['active'] = status
  }

  return api.get(url.GET_CAMPAIGNS, params);
};

// create a new campaign
export const addCampaign = (campaign: any) => api.create(url.ADD_CAMPAIGNS, campaign);

// modify a campaign
export const updateCampaign = async (campaign: any) => {
    const fieldsToOmitMore = [...fieldsToOmit,'ruleGroupId','rules','active']
    const cleanCampaignData = dynamicallyRemoveFields(campaign, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_CAMPAIGNS}/${campaign.id}`, cleanCampaignData)};

// remove an campaign
export const deleteCampaign = (campaignId: number) => api.delete(`${url.REMOVE_CAPAIGNS}/${campaignId}`);

// get campaigns
export const getCampaignStandings = (campaignId: number, offset: number = 0, limit: number = 20) => {
  const params = {
    offset: offset,
    limit: limit
  };

  return api.get(`${url.GET_CAMPAIGNS}/${campaignId}${url.STANDINGS}`, params);
}

// assign a rule to a group
export const assignGroupRule = (ruleId: number, groupId: number) => {
  return api.create(`${url.ADD_RULEGROUP}/${groupId}${url.ADD_RULE}/${ruleId}/assign`, {})
}

// remove a rule from a group
export const removeGroupRule = (ruleId: number, groupId: number) => {
  return api.create(`${url.ADD_RULEGROUP}/${groupId}${url.ADD_RULE}/${ruleId}/remove`, {})
}

// list all all tenants
export const getTenants = (offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_TENANTS, params);
};

// create a new program
export const addProgramTenant = (programId: number, tenant: any) => api.create(`${url.PROGRAMS}/${programId}${url.TENANTS}`, tenant);

// modify a program
export const updateProgramTenant = (programId: number, tenantId: number, tenant: any) => api.put(`${url.PROGRAMS}/${programId}${url.TENANTS}/${tenantId}`, tenant);
export const updateProgramTenantWithLogo = async (programId: number, tenantId: number, tenant: any) => {
    try {

            // If no logo file is present, send a JSON request body
            const locationData = {
                name: tenant.name,
                settings: tenant.settings,
            };

            // Make the request with JSON
            return await axios.put(
                `${url.PROGRAMS}/${programId}${url.TENANTS}/${tenantId}`,
                locationData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // Prevent retries
                    validateStatus: (status) => status < 400,
                }
            );

    } catch (error) {
        console.error("Failed to update location:", error);
        throw error;
    }
};
// upload a file to s3
export const uploadFiles = (uploads: any[], tenantId = null) => {
    if (tenantId)
    {
        return api.create(`${url.FILE_UPLOAD}?tenantId=${tenantId}`, { uploads });
    }

  return api.create(url.FILE_UPLOAD, { uploads });
}

// register user
export const registerUser = (role: UserRoleKeys = UserRoleKeys.RoleNone, payload: any) => {
  setXApiKey(config.X_API_KEY);

  let newUrl = url.GET_CLIENTS;

  switch (role) {
    case UserRoleKeys.RoleAdmin:
      newUrl = url.GET_ADMINS;
      break;
    case UserRoleKeys.RoleCorp:
      newUrl = url.GET_CORPS;
      break;
    case UserRoleKeys.RoleManager:
      newUrl = url.GET_MANAGERS;
      break;
    case UserRoleKeys.RoleNone:
      newUrl = url.GET_CLIENTS;
      break;
  }

  return api.create(newUrl, payload);
}

// list all all getting started video
export const getStartedVideos = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_STARTED_VIDEOS, params);
};

// create a new getting started video
export const addStartedVideo = (newStartedVideo: any) => api.create(url.ADD_STARTED_VIDEO, newStartedVideo);

// modify a getting started video
export const updateStartedVideo = async (startedVideo: any) =>{
    const fieldsToOmitMore = [
        ...fieldsToOmit,
        'downloadUrl',
        'streamUrl',
        'imageUrl',
        'thumbnailUrl',
        'expirationDatetime',
        'type'
    ];
    const cleanStartedVideoData = dynamicallyRemoveFields(startedVideo, fieldsToOmitMore);
    return await api.put(`${url.UPDATE_STARTED_VIDEO}/${startedVideo.id}`, cleanStartedVideoData)};

// remove a getting started video
export const deleteStartedVideo = (startedVideoId: number) => api.delete(`${url.REMOVE_STARTED_VIDEO}/${startedVideoId}`);

// list all notifications
export const getNotifications = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_NOTIFICATIONS, params);
};

// send notification
export const sendNotification = (newNotification: any) => api.create(url.SEND_NOTIFICATION, newNotification);

// list all channels
export const getNotificationChannels = (status?: number, offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.GET_NOTIFICATION_CHANNELS, params);
};

// list clients personal-goals
export const getClientsPersonalGoals = (state: 'active' | 'completed' = 'active', offset: number = 0, limit: number = 50) => {
  const currentUserRole = userManager.getUserRole();
  const groupIds = userManager.getUser()?.dbUser.groupIds

  const params = {
    state: state,
    offset: offset,
    limit: limit
  };

  // if user role is manager, set groupId to filter personal-goals
  if (currentUserRole === UserRoleKeys.RoleManager) {
    params['groupId'] = groupIds?.[0]
  }

  return api.get(url.GET_CLIENT_PERSONAL_GOALS, params);
}

// list clients reports
export const getClientsReports = (year: number = new Date().getFullYear(), month: number = new Date().getMonth(), offset: number = 0, limit: number = 50) => {
  const currentUserRole = userManager.getUserRole();
  const groupIds = userManager.getUser()?.dbUser.groupIds

  const params = {
    year,
    month,
    offset: offset,
    limit: limit
  };

  // if user role is manager, set groupId to filter personal-goals
  if (currentUserRole === UserRoleKeys.RoleManager) {
    params['groupId'] = groupIds?.[0]
  }

  return api.get(url.GET_CLIENT_REPORTS, params);
}

// delete report
export const deleteClientReport = (clientId: number, reportId: number) => api.delete(`clients/${clientId}/reports/${reportId}`);

// upload image on s3
export const uploadImageOnS3 = (url: string, image: File) => {
  return fetch(url, {
    method: "PUT",
    headers: {
      'Accept': `*/*`,
      'Content-Type': `image/*,application/*`,
    },
    body: image
  })
}

// knowledge base APIs
export const getKnowledgeBases = (offset: number = 0, limit: number = 20) => {
  const params = {
      offset: offset,
      limit: limit
  };

  return api.get(url.KNOWLEDGE_BASES, params);
};
export const createKnowledgeBase = (knowledgeBase: any) => api.create(`${url.KNOWLEDGE_BASES}`, knowledgeBase);
export const updateKnowledgeBase = async (knowledgeBaseId: number, knowledgeBaseUpdatable: any) => {
    const fieldsToOmitMore = [...fieldsToOmit, 'mediaUri'];
    const cleanKnowledgeBaseData = dynamicallyRemoveFields(knowledgeBaseUpdatable, fieldsToOmitMore);
 return await api.put(`${url.KNOWLEDGE_BASES}/${knowledgeBaseId}`, cleanKnowledgeBaseData)};
export const deleteKnowledgeBase = (knowledgeBaseId: number) => api.delete(`${url.KNOWLEDGE_BASES}/${knowledgeBaseId}`);

export const reassignClientToGroup = (groupId: number, clientIds: number[]) => {
  return api.create(`/groups/${groupId}/clients/assign`, {
    clientIds
  })
}
export const removeClientFromGroup = (groupId: number, clientIds: number[]) => {
    return api.create(`/groups/${groupId}/clients/remove`, {
        clientIds
    })
}
// chatbot guardrail APIs
export const getGuardrails = async () => {
  const resp = await api.get(url.GUARDRAILS);
  console.log(resp)
  if (resp.error) {
    return resp;
  }
  const { contentPolicy, topicPolicy } = resp;
  const formattedGuardrails = {
    contentPolicy: Object.keys(contentPolicy ?? {}).map(p => ({...contentPolicy[p], type: p})),
    topicPolicy: Object.keys(topicPolicy ?? {}).map(p => ({...topicPolicy[p], name: p}))
  };
  console.log(formattedGuardrails);
  return formattedGuardrails;
};
export const updateGuardrail = async (guardrailType, guardrailUpdate: any) => {
  const guardRailUpdatable = {
    [guardrailType]: {
      [guardrailUpdate.type ?? guardrailUpdate.name]: guardrailUpdate
    }
  };
  return await api.create(url.GUARDRAILS, guardRailUpdatable);
};
// guardrailIds should be an array of contentPolicy 'types' or topicPolicy 'names'
export const deleteGuardrail = async (guardrailType, guardrailIds) => {
  const guardrailDeletable = {
    [guardrailType] : guardrailIds
  };
  return await api.create(url.GUARDRAILS_DELETE, guardrailDeletable);
};

// chatbot history API
export const getClientChatHistory = async (clientId) => {
  const resp = await api.get(url.CHAT_HISTORY + `?client_id=${clientId}`);
  return resp;
};

// admin bot API(s)
export const postAdminBotMessage = async (message) => {
  const resp = await api.create(url.ADMIN_BOT_MESSAGE, { message });
  return resp?.data ?? resp;
}

export const modifyEnabledVideoIds = (ids: number[]) => {
  const idToken = localStorage.getItem("idToken");
  const tenantId = userManager.getUser()?.tenantDetails.id;

  // setAuthorization(idToken);

  return api.put(`${url.TENANTS}/${tenantId}`, {
    settings: {
      ...userManager.getUser()?.tenantDetails.settings,
      enabledGettingStartedVideoIds: ids
    }
  })
}

export const assumeRole = async (tenantId:string, role:string) => {
  return await api.create(url.ROLES, {
    tenantId,
    role
  });
};

export const revertAssumedRole = async (roleAssumptionId:number) => {
  return await api.delete(url.ROLES + `/${roleAssumptionId}`);
};
