import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteManager, getManagers, sendNewInvitation, updateManager} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import InviteModal from "Components/Modal/InviteModal";
import {userManager, UserRoleKeys} from "utils/UserManager";
import { convertUTC2Local } from "utils";
import DeleteModal from "Components/Modal/DeleteModal";
import {Link, useLocation, useParams} from "react-router-dom";
import ManagerModal from "Components/Modal/ManagerModal";
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

function capitalizeEachWord(string: string): string {
    return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
}

const ManagersPage = () => {
    const [managersData, setManagersData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
    const [title, setTitle] = useState<string | undefined>("Managers Page"); // Initial title

    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const { classification } = location.state || {}; // Access the passed state
    useEffect(() => {
        if (classification)
        {
            setTitle(capitalizeEachWord(classification));
        }

    }, [classification]);

    const currentUser = userManager;

    // Extract roleManagers and dynamic titles
    const roleManagers = tenantSetting?.['classifications']?.['role_manager'] || [];
    const classificationTitle = roleManagers.find(
        (role) => role.title.toLowerCase() === classification?.toLowerCase()
    )?.title;

    // Determine coachTitle
    const coachTitle = classificationTitle
        || (!currentUser.isCorporate() && tenantSetting?.['use_coach_name'])
        || 'Managers';
    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateInvite = (newInvite: any) => {
        const createInvite = async () => {
            try {

                newInvite.classification = classification;
                console.log(newInvite);
                const response = await sendNewInvitation(UserRoleKeys.RoleManager, newInvite); // Add parameters if needed
                if (response.success) {
                    toast.success("New invitation sent.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to send new invite.");
                }
            } catch (error) {

                toast.error("Failed to send new invite.");
            }
        };

        createInvite();
    }

    const handleEditManager = async (manager: any) => {
        try {
            const payload = {
                id: manager.id,
                name: manager.name,
                classification: manager.classification,
                phoneNumber: manager.phoneNumber
            }
            const response = await updateManager(payload); // Add parameters if needed
            if (response.success) {
                setManagersData((state) => {
                    const findIdx = state.findIndex(item => item?.id === manager.id);
                    if (!isNaN(findIdx)) {
                        state[findIdx] = response.data;
                    }
                    return [...state];
                });
                toggleEditModal();
            } else {
                // Display error notification
                toast.error(response.error || "Failed to update manager.");
            }
        } catch (error) {
            toast.error("Failed to update manager.");
        }
    }


    const handleDeleteManager = async () => {
        try {
            const response = await deleteManager(valueToBeModified.id); // Add parameters if needed
            if (response.success) {
                toast.success('Manager deleted');
                setManagersData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);

            } else {
                // Display error notification
                toast.error(response.error || "clients must be re-assigned before deleting a manager");
            }
        } catch (error: any) {
            toast.error(String(error) || "clients must be re-assigned before deleting a manager");
        }
    }
    useEffect(() => {
        const fetchManagers = async () => {
            setLoading(true);
            try {
                const response = await getManagers(1); // Fetch all managers
                if (response.success) {
                    // Filter managers by classification
                    const filteredManagers = response.data.managers.filter(
                        (manager) =>
                            manager.classification?.toLowerCase() === classification?.toLowerCase() ||
                            ( manager.classification === null)

                    );
                    setManagersData(filteredManagers);
                } else {
                    toast.error(response.error || "Failed to fetch managers.");
                }
            } catch (error) {
                toast.error("Failed to fetch managers.");
            } finally {
                setLoading(false);
            }
        };

        fetchManagers();
    }, [classification]);

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                cell: (info) => <RoleCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Classification", // New header for Classification
                accessorKey: "classification",
                cell: (info) => {
                    const value = info.getValue();
                    const badgeColor = value ? "success" : "danger"; // success for assigned, danger for unassigned
                    return (
                        <span className={`badge-soft-${badgeColor} ms-1 badge bg-${badgeColor} rounded-pill`}>
            {value ? value : "Unassigned"}
          </span>
                    );
                },
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            }
            // Add more columns as needed
        ],
        []
    );



    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                <TableContainer
                    columns={columns}
                    data={managersData || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    isAddButton={true}
                    isCustomPageSize={true}
                    handleUserClick={toggleNewModal}
                    SearchPlaceholder={`Search ${coachTitle.toLowerCase()}...`}
                    pagination="pagination"
                    buttonClass="btn btn-success btn-rounded"
                    buttonName={`Invite New ${coachTitle}`}
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"

                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                            }
                            <InviteModal initialData={{sendText: false, sendEmail: true}} isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateInvite} />
                            <ManagerModal classification={classificationTitle} isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditManager} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this {`${coachTitle.toLowerCase()}`}? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteManager}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ManagersPage;
