import FileUploader from 'Components/Common/FileUploader';
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { generalizeValue } from 'utils';
import { toast, ToastContainer } from 'react-toastify';
import { sendNewInvitation } from 'helpers/backend_helper';
import { userManager, UserRoleKeys } from 'utils/UserManager';

const InviteModal = ({ 
  showManagersDropdown = false,
  showTenantsDropdown = false,
  // enable/disable phone number field to capture for invite
  retrievePhone = false,
  // allow for email/phone number invite method(s) to be toggled
  enableInviteToggle = false,
  initialData = {sendText: true, sendEmail: true},
  // flag to distinguish invite creation from a resend
  isResend = false,
  managers = [] as any[],
  tenants = [] as any[],
  isOpen,
  toggle,
  onSave,
  batchEnabled = false }) => {
    const [newData, setNewData] = useState<any>(initialData);
    const [managerId, setManagerId] = useState<number>();
    const [tenantId, setTenantId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    const [batchFile, setBatchFile] = useState<File>();
    const [batchOpen, setBatchOpen] = useState(false);

    useEffect(() => {
      setNewData(initialData);
    }, [batchOpen]);

    const handleSave = async () => {
        if (enableInviteToggle && !(newData.sendText || newData.sendEmail)) {
          toast.error("At least 1 invite method must be selected");
          return;
        }

        if (batchOpen && batchFile) {
          setLoading(true);
          await handleBulkUpload();
          setLoading(false);
        }
        else if (showManagersDropdown) {
            onSave && onSave(newData, managerId)
        } else if (showTenantsDropdown) {
            onSave && onSave(newData, tenantId)
        } else {
            onSave && onSave(newData)
        }
    }

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value)
        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue
        }))
    }

    const handlePhoneInput = (e) => {
      const generalizedValue = generalizeValue(e.target.type, e.target.value)

      if (/^\d{0,10}$/.test(generalizedValue)) {
        setNewData((state) => ({
          ...state,
          [e.target.id]: generalizedValue
      }))
      }
  }
    const handleResourceFileChange = (files: File[]) => {
      if (files.length > 0) {
        const currentFile = files[0];
        console.log("on file change");
        if (currentFile.type !== "text/csv") {
          toast.error("Only files of type CSV are accepted for multi-uploads.");
          return;
        }
        setBatchFile(currentFile);
      }
    }

    const handleBulkUpload = async () => {
      if (!batchFile) return;

      const managerOptions = [
        ...managers,
        ...(userManager.isManager() ? [userManager.getUser()?.dbUser] : [])
      ];

      if (batchFile.type !== "text/csv" || batchFile.size === 0) {
        toast.error("Only files of type CSV and non-zero size are accepted for multi-uploads.");
        return;
      }

      const bulkInvites: any[] = [];
      try {
        // Split the CSV string into rows
        const rows = (await batchFile.text()).trim().split('\n');
        const headers = rows.shift()?.split(',').map(header => header.trim().toLowerCase()) ?? [];

        // verify that the headers
        if (headers.some(h => !["name", "email", "phone", "manager"].includes(h))) {
          toast.error("CSV must include the following headers: name, email, phone, manager");
          return;
        }
        
        // parse the CSV
        const inviteRecords: any[] = rows.map(row => {
          const values = row.split(',');
          // Create an object using the header row as keys
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index].trim(); // Trim to remove extra spaces
            return obj;
          }, {});
        });
        bulkInvites.push(...inviteRecords);
        
        // verify individual values for each row in the CSV
        const invalidEmails = bulkInvites.filter(r => !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(r.email)) ?? [];
        const invalidPhoneNumbers = bulkInvites.filter(r => !(/^\d{10}$/.test(r.phone) || /^$/.test(r.phone))) ?? [];
        const invalidManagers = bulkInvites.filter(r => !managerOptions.find(m => m.name === r.manager)) ?? [];

        if (invalidEmails.length) {
          toast.error(`Invalid email(s) detected: ${invalidEmails.map(r => r.email).join(", ")}`, {
            autoClose: false
          });
        }
        if (invalidPhoneNumbers.length) {
          toast.error(`Invalid phone number(s) detected: ${invalidPhoneNumbers.map(r => r.phone).join(", ")}`, {
            autoClose: false
          });
        }
        if (invalidManagers.length) {
          toast.error(`Invalid manager assignment(s) detected: ${invalidManagers.map(r => `${r.name} -> ${r.manager}`).join(", ")}`, {
            autoClose: false
          });
        }

        if (invalidEmails.length > 0 || invalidPhoneNumbers.length > 0 || invalidManagers.length > 0) {
          return;
        }
      } catch (err) {
        console.error(err);
        toast.error("failed to parse the CSV file.");
        return;
      }

      // track API errors to determine modal closure
      let errorCount = 0;

      const batchSize = 5;
      for (let i = 0; i < bulkInvites.length; i += batchSize) {
        const safeInvite = async (inviteData) => {
          const assignedManager = managerOptions.find(m => m.name === inviteData.manager);
          
          const newInvite = {
            ...newData,
            groupId: assignedManager.groupIds[0],
            emailInvited: inviteData.email,
            nameInvited: inviteData.name
          };
          if (inviteData.phone) {
            newData.phoneNumberInvited = inviteData.phone;
          }

          try {
            await sendNewInvitation(UserRoleKeys.RoleNone, newInvite);
            toast.success(`Invite sent to: ${inviteData.name}`);
          } catch (error) {
            console.error(error);
            toast.error(`${inviteData.name} - ${inviteData.email}.\n${error}`, {
              autoClose: false
            });
            errorCount++;
          }
        };
        await Promise.all(bulkInvites.slice(i, i + batchSize).map(i => safeInvite(i)));
        (async () => {
          await new Promise(resolve => setTimeout(resolve, 2_000)); // Pause for 1 second
          console.log("Pause to limit email throttling...");
      })();
      }

      if (errorCount === 0) {
        toggle();
      }
    }

    useEffect(() => {
        setManagerId(undefined);
        setTenantId(undefined);
        setNewData(initialData)
    }, [isOpen]);

    // Group managers by classification or another property
    const groupedManagers: Record<string, { id: number; name: string }[]> = managers.reduce(
        (groups, manager) => {
            const classification = manager.classification || 'Unclassified';
            if (!groups[classification]) {
                groups[classification] = [];
            }
            groups[classification].push(manager);
            return groups;
        },
        {} as Record<string, { id: number; name: string }[]>
    );



    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ToastContainer />
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    {isResend ? "Resend Invite" : "Invite Someone"}
                </div>
            </ModalHeader>
            <ModalBody>
            <Row className="justify-content-end" xs="auto">
                    {!isResend && batchEnabled && <Col className="btn-group" role="status">
                        <input
                          id="batch-closed" className="btn-check" name="single" type="radio" autoComplete="off"
                          defaultChecked checked={!batchOpen}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="accepted" onClick={() => setBatchOpen(false)}>
                            Single Invite
                        </label>
                        <input
                          id="batch-closed" className="btn-check" name="multi" type="radio" autoComplete="off"
                          checked={batchOpen}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted" onClick={() => setBatchOpen(true)}>
                            Multi-Invite
                        </label>
                    </Col>}
                </Row>
                  <>
                    {!isResend && batchEnabled && batchOpen && <>
                      <FileUploader hint="Please upload CSV file here." multiple={false} onSend={handleBulkUpload} onChange={handleResourceFileChange} />
                      <h5>File Format</h5>
                      <p><b>Headers:</b> name, email, phone, manager</p>
                      <p><b>Name:</b> The full name of the client.</p>
                      <p><b>Email:</b> The full valid email of the client.</p>
                      <p><b>Phone:</b> 10 consecutive digits in the format XXXXXXXXXX. This field may be left blank.</p>
                      <p><b>Manager:</b> The full name of the Manager in the Admin App to assign to the client.</p>
                    </>}
                  <form>
                    {!batchOpen && <div className="mb-3">
                        <label htmlFor="nameInvited" className="form-label">Name</label>
                        <input value={newData?.nameInvited ?? ''} type="text" className="form-control" id="nameInvited" placeholder="Enter name" onChange={handleFieldInput} />
                    </div>}
                    {!(isResend || batchOpen) && <div className="mb-3">
                        <label htmlFor="emailInvited" className="form-label">Email address</label>
                        <input value={newData?.emailInvited ?? ''} type="email" className="form-control" id="emailInvited" placeholder="Enter email" onChange={handleFieldInput} />
                    </div>}
                    {!(isResend || batchOpen) && retrievePhone && <div className="mb-3">
                        <label htmlFor="emailInvited" className="form-label">Phone Number</label>
                        <input value={newData?.phoneNumberInvited ?? ''} type="text" className="form-control" id="phoneNumberInvited" placeholder="Enter phone number" onChange={handlePhoneInput} />
                    </div>}
                    {showManagersDropdown && !batchOpen && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Managers
                            </label>
                            <select
                                className="form-select form-control"
                                value={managerId ?? 'Select'}
                                onChange={(e) => {
                                    setManagerId(Number(e.target.value));
                                }}
                            >
                                <option>Select</option>
                                {Object.entries(groupedManagers).map(([classification, managers]) => (
                                    <optgroup key={classification} label={classification}>
                                        {managers.map(manager => (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                        </div>
                    )}
                    {showTenantsDropdown && !batchOpen && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Locations</label>
                            <select className="form-select form-control" value={tenantId ?? 'Select'} onChange={(e) => {
                                setTenantId(Number(e.target.value))
                            }}>
                            <option>Select</option>
                            {tenants?.map(tenant => (
                                <option key={tenant.id} value={tenant.id}>{tenant.name}</option>
                            ))}
                            </select>
                        </div>
                    )}
                    {enableInviteToggle &&
                    <>
                      <h5 className="form-label">Toggle Invitation Methods</h5>
                      <div className="form-check mb-3">
                        <label htmlFor="nameInvited" className="form-check-label">Text</label>
                        <input checked={newData?.sendText ?? false} value={newData?.sendText ?? false} type="checkbox" className="form-check-input" id="sendText" onChange={() => {setNewData({...newData, sendText: !newData.sendText})}} />
                      </div>
                      <div className="form-check mb-3">
                        <label htmlFor="nameInvited" className="form-check-label">Email</label>
                        <input checked={newData?.sendEmail ?? false} value={newData?.sendEmail ?? false} type="checkbox" className="form-check-input" id="sendEmail" onChange={() => {setNewData({...newData, sendEmail: !newData.sendEmail})}} />
                      </div>
                    </>}
                    <p>Please fill out the form to send an invitation.</p>
                </form>
                </>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    disabled={loading}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    disabled={loading}
                >
                    Send Invitation
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default InviteModal;
